<template>
  <div class="positions-container">
    <h1 class="title">Current Round Position</h1>
    <div v-if="isMounting" class="search-spinner"></div>
    <div class="info-section">
      <div class="label-item">
        <label>Round Now:</label>
        <span>{{ currentRound }}</span>
      </div>
      <div class="label-item">
        <label>Current ETH Price:</label>
        <span>{{ currentETHPrice }}</span>
      </div>
    </div>
    <table class="position-table">
      <thead>
        <tr>
          <th>Strike</th>
          <th>Position</th>
          <th>Shares</th>
          <th>Values</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="position in current_position" :key="position.slot">
          <td>{{ position.strike }}</td>
          <td>{{ position.type }}</td>
          <td>{{ position.shares }}</td>
          <td>{{ position.values }}</td>
        </tr>
      </tbody>
    </table>

    <div class="dropdown-section" @click="toggleDropdown">
      <span :class="{ 'arrow-up': showDropdown, 'arrow-down': !showDropdown }"></span>
      <div class="tooltip-text">{{ showDropdown ? 'Collapse to hide' : 'Expand for previous position' }}</div>
    </div>

    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <div v-if="showDropdown" class="previous-position-section">
      <h1 class="title">Previous Round Position</h1>
      <div class="input-container">
        <div class="input-row">
          <input type="number" class="small-input" id="previusRound" placeholder="Search Round ..." v-model.number="previusRound" @input="validatePreRound" min="1"
            step="1" />
          <button type="submit" @click="search" style="border-radius: 12px; background-color: #13151b;"><i class="fa fa-search" style="color: white;"></i></button>
          <!-- <button @click="search" class="search-button">Search</button> -->
        </div>
        <span v-if="preRoundError" class="error">{{ preRoundError }}</span>
      </div>
      <div v-if="isSearching" class="search-spinner"></div>
      <div class="info-section">
        <div class="label-item">
          <label>Searched Round:</label>
          <span>{{ previusRound }}</span>
        </div>
        <div class="label-item">
          <label>Close Round ETH Price:</label>
          <span>{{ closeRoundETHPrice }}</span>
        </div>
      </div>
      <table class="position-table">
        <thead>
          <tr>
            <th>Strike</th>
            <th>Position</th>
            <th>Shares</th>
            <th>Values</th>
            <th>Redeem Available</th>
            <th>Select</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="position in previus_position" :key="position.slot">
            <td>{{ position.strike }}</td>
            <td>{{ position.type }}</td>
            <td>{{ position.shares }}</td>
            <td>{{ position.values }}</td>
            <td>{{ position.redeems }}</td>
            <td>
              <input type="checkbox" :value="{ slot: position.slot, type: position.type === 'CALL' ? 0 : 1 }"
                v-model="selectedPositions" />
            </td>
          </tr>
        </tbody>
      </table>
      <div class="redeem-button-container">
        <button @click="handleRedeemSpot" class="open-position-button" style="width: 120px; font-size: 16px;"
          :disabled="selectedPositions.length === 0" 
          :class="{
          disabled: selectedPositions.length === 0,
          enabled: selectedPositions.length > 0
        }">Redeem</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject, onMounted, computed, watch, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { redeemSpot } from '../assets/scripts/web3utils'
import { startTransaction, completeTransaction, failTransaction } from '../assets/scripts/bannerutils'
import '../assets/styles/global-style.css'

export default {
  setup() {
    const store = useStore()
    const modal = inject('web3modal')
    const root = ref(null)

    // Vars
    let ethDecimal = computed(() => store.getters.ETH_DECIMAL)
    let homePageParams = computed(() => store.getters.homePageParams)
    let currentETHPrice = ref(0)
    let current_position = ref([])
    let previus_position = ref([])
    let currentRound = ref(0)
    let closeRoundETHPrice = ref(0)
    let previusRound = ref(null)
    let preRoundError = ref('')
    let selectedPositions = ref([])
    let lastStrike = ref()

    // Dropdown toggle state
    let showDropdown = ref(false)
    let dropdownLabel = computed(() => (showDropdown.value ? 'Hide Previous Position' : 'Click To Show Previous Position'))

    let isMounting = ref(false)
    let isSearching = ref(false)

    const updateData = () => {
      if (homePageParams.value && homePageParams.value.length > 0) {
        currentETHPrice.value = parseFloat(Number(homePageParams.value.CurrentETHPrice) / ethDecimal.value).toFixed(2)
        currentRound.value = homePageParams.value.RoundNow.toNumber()
        lastStrike.value = homePageParams.value.LastStrikeTime.toNumber()
        previusRound.value = currentRound.value - 1
      }
    }

    const toggleDropdown = () => {
      showDropdown.value = !showDropdown.value
    }

    watch(homePageParams, updateData, { immediate: true })

    const validatePreRound = () => {
      if (previusRound.value < 1 || previusRound.value >= currentRound.value || !Number.isInteger(previusRound.value)) {
        preRoundError.value = 'invalid round'
      } else {
        preRoundError.value = ''
      }
    }

    const search = async () => {
      validatePreRound()
      if (!preRoundError.value) {
        isSearching.value = true
        previus_position.value = null
        await store.dispatch('fetchRoundPositionSingle', { p_modal: modal, p_preRound: Number(previusRound.value), p_isSpot: true })
        previus_position.value = store.state.preSpotPosition
        closeRoundETHPrice.value = parseFloat(store.state.closeEthPriceSpot.toNumber() / ethDecimal.value).toFixed(2)
        isSearching.value = false
      }
    }

    const handleRedeemSpot = async () => {
      try {
        const slotArray = selectedPositions.value.map(position => position.slot)
        const typeArray = selectedPositions.value.map(position => position.type)

        startTransaction(root.value, 'Redeem')
        let [tx, err] = await redeemSpot(modal, previusRound.value, typeArray, slotArray)
        if (tx) {
          await tx.wait()
          await store.dispatch('fetchRoundPositionSpot', { p_modal: modal, p_curRound: currentRound.value, p_preRound: previusRound.value })
          previus_position.value = store.state.preSpotPosition
          completeTransaction(root.value, tx.hash)
        } else {
          console.log('Redeem Spot Failed')
          failTransaction(root.value, err)
        }
      } catch (error) {
        console.log('handleRedeemSpot error: ', error)
        failTransaction(root.value, error.message)
      }
    }

    watch(
      () => [store.getters.curSpotPosition, store.getters.preSpotPosition],
      ([newCurSpotPosition, newPreSpotPosition]) => {
        current_position.value = newCurSpotPosition
        previus_position.value = newPreSpotPosition
      },
      { immediate: true }
    )

    onMounted(async () => {
      isMounting.value = true
      root.value = getCurrentInstance().proxy.$root
      if (!homePageParams.value) {
        await store.dispatch('fetchHomePageInfo', { p_modal: modal })
        homePageParams = computed(() => store.getters.homePageParams)
        ethDecimal = computed(() => store.getters.ETH_DECIMAL)
      }
      if (!store.getters.isDataLoadedSpot) {
        await store.dispatch('fetchRoundPositionSpot', { p_modal: modal, p_curRound: currentRound.value, p_preRound: currentRound.value - 1 })
      }
      current_position.value = store.state.curSpotPosition
      previus_position.value = store.state.preSpotPosition
      closeRoundETHPrice.value = parseFloat(store.state.closeEthPriceSpot.toNumber() / ethDecimal.value).toFixed(2)
      isMounting.value = false
    })

    return {
      modal,
      currentETHPrice,
      currentRound,
      previusRound,
      preRoundError,
      closeRoundETHPrice,
      current_position,
      previus_position,
      selectedPositions,
      showDropdown,
      dropdownLabel,
      isMounting,
      isSearching,
      search,
      handleRedeemSpot,
      toggleDropdown
    }
  },
}
</script>