<template>
  <div class="positions-container">
    <h1 class="title">Current Round Position</h1>
    <div v-if="isMounting" class="search-spinner"></div>
    <div class="info-section">
      <div class="label-item">
        <label>Round Now:</label>
        <span>{{ currentRound }}</span>
      </div>
      <div class="label-item">
        <label>Current ETH Price:</label>
        <span>{{ currentETHPrice }}</span>
      </div>
    </div>
    <table class="position-table">
      <thead>
        <tr>
          <th>Strike</th>
          <th>Position</th>
          <th>Shares</th>
          <th>Margin</th>
          <th>Values</th>
          <th>Leverage</th>
          <th>Countdown</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="position in current_position" :key="position.round">
          <td>{{ position.strike }}</td>
          <td>{{ position.type }}</td>
          <td>{{ position.shares }}</td>
          <td>{{ position.margin }}</td>
          <td>{{ position.values }}</td>
          <td>{{ position.leverage }}</td>
          <td>{{ formatCountdown(position.liquidationTime) }}</td>
          <td>
            <button class="open-position-button" 
              style="width: 100px !important; height: 40px !important; font-size: 12px !important; text-align: center !important; white-space: nowrap !important;" 
              @click="openAddMarginWindow(position)">
              Add Margin
            </button>
          </td>
          <!-- <td><button class="search-button" @click="openAddMarginWindow(position)">Add Margin</button></td> -->
        </tr>
      </tbody>
    </table>

    <!-- Dropdown menu to show/hide previous round section -->
    <!-- <div class="dropdown-section">
      <button class="toggle-button" @click="toggleDropdown">
        {{ dropdownLabel }}
      </button>
    </div> -->
    <div class="dropdown-section" @click="toggleDropdown">
      <span :class="{ 'arrow-up': showDropdown, 'arrow-down': !showDropdown }"></span>
      <div class="tooltip-text">{{ showDropdown ? 'Collapse to hide' : 'Expand for previous position' }}</div>
    </div>

    <!-- Hidden section that shows when dropdown is clicked -->
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <div v-if="showDropdown" class="previous-position-section">
      <h1 class="title">Previous Round Position</h1>
      <div class="input-container">
        <div class="input-row">
          <input type="number" class="small-input" id="previusRound" v-model.number="previusRound" @input="validatePreRound" min="1"
            step="1" />
            <button type="submit" @click="search" style="border-radius: 12px; background-color: #13151b;"><i class="fa fa-search" style="color: white;"></i></button>
          <!-- <button @click="search" class="search-button">Search</button> -->
        </div>
        <span v-if="preRoundError" class="error">{{ preRoundError }}</span>
      </div>
      <div v-if="isSearching" class="search-spinner"></div>
      <div class="info-section">
        <div class="label-item">
          <label>Searched Round: </label>
          <span>{{ previusRound }}</span>
        </div>
        <div class="label-item">
          <label>Close Round ETH Price: </label>
          <span>{{ closeRoundETHPrice }}</span>
        </div>
      </div>
      <table class="position-table">
        <thead>
          <tr>
            <th>Strike</th>
            <th>Position</th>
            <th>Shares</th>
            <th>Margin</th>
            <th>Values</th>
            <th>Leverage</th>
            <th>Redeem Available</th>
            <th>Select</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="position in previus_position" :key="position.round">
            <td>{{ position.strike }}</td>
            <td>{{ position.type }}</td>
            <td>{{ position.shares }}</td>
            <td>{{ position.margin }}</td>
            <td>{{ position.values }}</td>
            <td>{{ position.leverage }}</td>
            <td>{{ position.redeems }}</td>
            <td>
              <input type="checkbox" :value="{ slot: position.slot, type: position.type === 'CALL' ? 0 : 1 }"
                v-model="selectedPositions" :disabled="position.redeems === parseFloat(0).toFixed(2)" />
            </td>
          </tr>
        </tbody>
      </table>
      <div class="redeem-button-container">
        <button @click="handleRedeemLevg" class="open-position-button" style="width: 120px; font-size: 16px;"
          :disabled="selectedPositions.length === 0" 
          :class="{
          disabled: selectedPositions.length === 0,
          enabled: selectedPositions.length > 0
        }">Redeem</button>
      </div>
    </div>

    <div v-if="showDialog" class="overlay">
      <div class="dialog">
        <div class="dialog-content">
          <h2>Confirm Add Margin</h2>
          <p>Type: {{ addMarginPosition.type }}</p>
          <p>Strike: {{ addMarginPosition.strike }}</p>
          <div>
            <label for="addMarginValueInput">Enter Margin: </label>
            <input type="number" id="addMarginValueInput" v-model.number="addMarginValueInput" min="0.01" step="0.01"
              @input="validateAddMarginAmount" />
          </div>
          <span v-if="marginInputError" class="error">{{ marginInputError }}</span>

          <div v-if="isLoading" class="spinner"></div>

          <div v-if="newLiquidationTimeFormatted" class="input-controls">
            <label>Liquidation Count Down: <span>{{ newLiquidationTimeFormatted }}</span></label>
          </div>
          <div class="buttons">
            <button class="buttons-confirm" @click="handleAddMargin"
              :disabled="!isValidAddMarginAmount">Confirm</button>
            <button class="buttons-cancel" @click="cancelMargin">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject, onMounted, computed, watch, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { ethers } from 'ethers'
import { redeemLevg, addMargin, fetchPreviewAddMarginStat, allowance, approve } from '../assets/scripts/web3utils'
import { startTransaction, completeTransaction, failTransaction } from '../assets/scripts/bannerutils'
import '../assets/styles/global-style.css'

export default {
  setup() {
    const store = useStore()
    const modal = inject('web3modal')
    const root = ref(null)

    // Vars
    let ethDecimal = computed(() => store.getters.ETH_DECIMAL)
    let usdtDecimal = computed(() => store.getters.USDT_DECIMAL)
    let homePageParams = computed(() => store.getters.homePageParams)
    let currentETHPrice = ref(0)
    let current_position = ref([])
    let previus_position = ref([])
    let currentRound = ref(0)
    let previusRound = ref(null)
    let preRoundError = ref('')
    let lastStrike = ref()
    let closeRoundETHPrice = ref(0)
    let selectedPositions = ref([])
    let showDialog = ref(false)
    let addMarginPosition = ref({})
    let addMarginValueInput = ref(0)
    let marginInputError = ref(null)
    let newLiquidationTimeFormatted = ref('')
    let isLoading = ref(false)
    let isValidAddMarginAmount = ref(false)

    // Dropdown toggle state
    let showDropdown = ref(false)
    let dropdownLabel = computed(() => (showDropdown.value ? 'Hide Previous Position' : 'Click To Show Previous Position'))

    let isMounting = ref(false)
    let isSearching = ref(false)

    const updateData = () => {
      if (homePageParams.value && homePageParams.value.length > 0) {
        currentETHPrice.value = parseFloat(Number(homePageParams.value.CurrentETHPrice) / ethDecimal.value).toFixed(2)
        currentRound.value = homePageParams.value.RoundNow.toNumber()
        lastStrike.value = homePageParams.value.LastStrikeTime.toNumber()
        previusRound.value = currentRound.value - 1
      }
    }

    const toggleDropdown = () => {
      showDropdown.value = !showDropdown.value
    }

    watch(homePageParams, updateData, { immediate: true })

    const validatePreRound = () => {
      if (previusRound.value < 1 || previusRound.value >= currentRound.value || !Number.isInteger(previusRound.value)) {
        preRoundError.value = 'invalid round'
      } else {
        preRoundError.value = ''
      }
    }

    const search = async () => {
      validatePreRound()
      if (!preRoundError.value) {
        isSearching.value = true
        previus_position.value = null
        await store.dispatch('fetchRoundPositionSingle', { p_modal: modal, p_preRound: Number(previusRound.value), p_isSpot: false })
        previus_position.value = store.state.preLevgPosition
        closeRoundETHPrice.value = parseFloat(store.state.closeEthPriceLevg.toNumber() / ethDecimal.value).toFixed(2)
        isSearching.value = false
      }
    }

    const formatCountdown = (liquidationTime) => {
      if (liquidationTime == 0) return 'N/A'
      const now = Math.floor(Date.now() / 1000)
      const diff = liquidationTime - now

      if (diff <= 0) return 'Liquidation'

      const hours = Math.floor(diff / 3600)
      const minutes = Math.floor((diff % 3600) / 60)
      const seconds = diff % 60

      return `${hours}h ${minutes}m ${seconds}s`
    }

    watch(addMarginValueInput, async (newValue) => {
      if (newValue > 0) {
        newLiquidationTimeFormatted.value = ''
        marginInputError.value = ''
        isLoading.value = true
        setTimeout(async () => {
          try {
            if (!modal.getAddress()) {
              isLoading.value = false
              return
            }
            let _type = addMarginPosition.value.type === "CALL" ? Number(0) : Number(1)
            let newLiquidationTime = await fetchPreviewAddMarginStat(
              modal,
              modal.getAddress(),
              _type,
              addMarginPosition.value.slot,
              ethers.BigNumber.from(Number(addMarginValueInput.value) * usdtDecimal.value)
            )
            newLiquidationTimeFormatted.value = formatCountdown(newLiquidationTime)
          } catch (error) {
            console.log('Failed to fetch new liquidation time:', error)
          } finally {
            isLoading.value = false
          }
        }, 5000)
      }
    }, { immediate: false })

    const openAddMarginWindow = (position) => {
      addMarginPosition.value = position
      showDialog.value = true
    }

    const handleRedeemLevg = async () => {
      try {
        startTransaction(root.value, 'Redeem')
        const slotArray = selectedPositions.value.map(position => position.slot)
        const typeArray = selectedPositions.value.map(position => position.type)
        let [tx, err] = await redeemLevg(modal, previusRound.value, typeArray, slotArray)
        if (tx) {
          await tx.wait()
          console.log('Redeem Leverage Successfully! TX Hash: ' + tx.hash)
          await store.dispatch('fetchRoundPositionLevg', { p_modal: modal, p_curRound: currentRound.value, p_preRound: previusRound.value })
          previus_position.value = store.state.preLevgPosition
          completeTransaction(root.value, tx.hash)
        } else {
          console.log('Redeem Leverage Failed')
          failTransaction(root.value, err)
        }
      } catch (error) {
        console.log('handleRedeemLevg error: ', error)
        failTransaction(root.value, error.message)
      }
    }

    const validateAddMarginAmount = () => {
      const decimalPlaces = (addMarginValueInput.value.toString().split('.')[1] || '').length;

      if (addMarginValueInput.value <= 0) {
        marginInputError.value = 'Invalid amount of magin';
        newLiquidationTimeFormatted.value = null;
        isValidAddMarginAmount.value = false;
      } else if (decimalPlaces > 6) {
        marginInputError.value = 'Input cannot have more than 6 decimal places';
        newLiquidationTimeFormatted.value = null;
        isValidAddMarginAmount.value = false;
      } else {
        marginInputError.value = null;
        isValidAddMarginAmount.value = true;
      }
    }

    const handleAddMargin = async () => {
      try {
        if (addMarginValueInput.value <= 0) {
          marginInputError.value = 'Margin must be a positive value'
        } else {
          let _allowance = await allowance(modal, true)
          // let _inputUsdt = ethers.BigNumber.from(Number(addMarginValueInput.value)).mul(ethers.BigNumber.from(usdtDecimal.value))
          let _inputUsdt = ethers.BigNumber.from(parseFloat(addMarginValueInput.value).toFixed(6) * usdtDecimal.value)
          if (_allowance.lt(_inputUsdt)) {
            try {
              startTransaction(root.value, 'Approve')
              const [approveTx, err] = await approve(modal, _inputUsdt, true)
              if (approveTx) {
                await approveTx.wait()
                completeTransaction(root.value, approveTx.hash)
              }
              else {
                failTransaction(root.value, err)
                return
              }
            } catch (error) {
              console.log('Transaction failed:', error)
              failTransaction(root.value, error.message)
            }
          }
          startTransaction(root.value, 'AddMargin')
          let _type = addMarginPosition.value.type === "CALL" ? Number(0) : Number(1)
          let [tx, err] = await addMargin(modal, _type, addMarginPosition.value.slot, _inputUsdt)
          if (tx) {
            await tx.wait()
            await store.dispatch('fetchRoundPositionLevg', { p_modal: modal, p_curRound: currentRound.value, p_preRound: previusRound.value })
            current_position.value = store.state.curLevgPosition
            showDialog.value = false
            completeTransaction(root.value, tx.hash)
          } else {
            console.log('Add Margin Failed')
            failTransaction(root.value, err)
          }
        }
      } catch (error) {
        console.log('handleAddMargin error: ', error)
        failTransaction(root.value, error.message)
      }
    }

    const cancelMargin = () => {
      marginInputError.value = null
      addMarginValueInput.value = 0
      newLiquidationTimeFormatted.value = null
      showDialog.value = false
    }

    watch(
      () => [store.getters.curLevgPosition, store.getters.preLevgPosition],
      ([newCurLevgPosition, newPreLevgPosition]) => {
        current_position.value = newCurLevgPosition
        previus_position.value = newPreLevgPosition
      },
      { immediate: true }
    )

    onMounted(async () => {
      isMounting.value = true
      root.value = getCurrentInstance().proxy.$root
      if (!homePageParams.value) {
        await store.dispatch('fetchHomePageInfo', { p_modal: modal })
        homePageParams = computed(() => store.getters.homePageParams)
        ethDecimal = computed(() => store.getters.ETH_DECIMAL)
        usdtDecimal = computed(() => store.getters.USDT_DECIMAL)
      }
      if (!store.getters.isDataLoadedLevg) {
        await store.dispatch('fetchRoundPositionLevg', { p_modal: modal, p_curRound: currentRound.value, p_preRound: currentRound.value - 1 })
      }
      current_position.value = store.state.curLevgPosition
      previus_position.value = store.state.preLevgPosition
      closeRoundETHPrice.value = parseFloat(store.state.closeEthPriceLevg.toNumber() / ethDecimal.value).toFixed(2)
      setInterval(() => {
        if (current_position.value) current_position.value = [...current_position.value]
        if (previus_position.value) previus_position.value = [...previus_position.value]
      }, 1000)
      isMounting.value = false
    })

    return {
      modal,
      currentETHPrice,
      previusRound,
      preRoundError,
      currentRound,
      closeRoundETHPrice,
      current_position,
      previus_position,
      selectedPositions,
      showDialog,
      addMarginPosition,
      addMarginValueInput,
      marginInputError,
      newLiquidationTimeFormatted,
      isLoading,
      showDropdown,
      dropdownLabel,
      isMounting,
      isSearching,
      isValidAddMarginAmount,
      search,
      formatCountdown,
      openAddMarginWindow,
      handleRedeemLevg,
      handleAddMargin,
      validateAddMarginAmount,
      cancelMargin,
      toggleDropdown
    }
  },
}
</script>