<template>
  <div class="positions-container">
    <h3 class="title">Liquidity</h3>

    <div v-if="isMounting" class="search-spinner"></div>

    <table class="position-table">
      <thead>
        <tr>
          <th>TVL</th>
          <th>Deposits</th>
          <th>Withdraws</th>
          <th>Shares</th>
          <th>Max to Withdraw</th>
          <th>Profits</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ protocalTVL }}</td>
          <td>{{ deposits }}</td>
          <td>{{ withdraws }}</td>
          <td>{{ shares }}</td>
          <td>{{ maxToWithdraw_display }}</td>
          <td>{{ profits }}</td>
        </tr>
      </tbody>
    </table>

    <div class="header-card">
      <button @click="toggleOperation('DEPO')" :class="['call-button', { selected: selectedOperation === 'DEPO' }]"
        style="font-size: 16px;">Deposit</button>
      <button @click="toggleOperation('WITH')" :class="['put-button', { selected: selectedOperation === 'WITH' }]"
        style="font-size: 16px;">Withdraw</button>
    </div>

    <!-- Deposit Section -->
    <div v-if="selectedOperation === 'DEPO'" class="deposit-section">
      <div class="form-row">
        <label for="amount">Amount</label>
        <div style="width: 100px;">
          <input id="amount" type="number" v-model="depositAmount" class="small-input" @input="handleInput('depositAmount')" />
        </div>
      </div>
      <!-- Show deposit error message -->
      <div class="form-row">
        <div v-if="depositError" style="margin-left: 65% !important;" class="error">{{ depositError }} </div>
      </div>
      <div class="form-row">
        <label>Estimate Shares</label>
        <label>{{ depositESTShares }}</label>
      </div>
      <div class="form-row" style="display: flex; justify-content: flex-end;">
        <button class="open-position-button" @click="confirmDeposit" style="width: 120px !important; font-size: 16px;"
        :disabled="depositError !== '' || !depositAmount" 
        :class="{
          disabled: depositError !== '' || !depositAmount,
          enabled: depositError === '' && depositAmount
        }">Confirm</button>
      </div>
    </div>

    <!-- Withdraw Section -->
    <div v-if="selectedOperation === 'WITH'" class="withdraw-section">
      <div class="form-row">
        <label for="type">Type</label>
        <select v-model="withdrawType" class="option-list">
          <option value="SHARE">Share</option>
          <option value="USDT">USDT</option>
        </select>
      </div>
      <div class="form-row">
        <label for="withdrawAmount">Amount</label>
        <div style="width: 100px;">
          <input id="withdrawAmount" type="number" v-model="withdrawAmount" class="small-input" @input="handleInput('withdrawAmount')" />
        </div>
      </div>
      <!-- Show withdraw error message -->
      <div class="form-row">
        <div v-if="withdrawError" style="margin-left: 65% !important;" class="error">{{ withdrawError }}</div>
      </div>
      <!-- Conditional third row based on withdrawType -->
      <div class="form-row" v-if="withdrawType === 'SHARE'">
        <label>Est. Amount Receive</label>
        <label>{{ withdrawESTValues }}</label>
      </div>
      <div class="form-row" v-else>
        <label>Est. Share Spent</label>
        <label>{{ withdrawESTShares }}</label>
      </div>

      <div class="form-row" style="display: flex; justify-content: flex-end;">
        <button class="open-position-button" @click="confirmWithdraw" style="width: 120px !important; font-size: 16px;"
        :disabled="withdrawError !== '' || !withdrawAmount" 
        :class="{
          disabled: withdrawError !== '' || !withdrawAmount,
          enabled: withdrawError === '' && withdrawAmount
        }">Confirm</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject, onMounted, computed, watch, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { fetchPreviewConvertToAssets, fetchPreviewConvertToShares, deposit, withdraw, redeem, approve, allowance } from '../assets/scripts/web3utils'
import { startTransaction, completeTransaction, failTransaction } from '../assets/scripts/bannerutils'
import { ethers } from 'ethers'
import '../assets/styles/global-style.css'

export default {
  setup() {
    const store = useStore()
    const modal = inject('web3modal')
    const root = ref(null)
    let homePageParams = computed(() => store.getters.homePageParams)
    let liquidityInfo = computed(() => store.getters.liquidityInfo)
    let usdtDecimal = computed(() => store.getters.USDT_DECIMAL)
    let isMounting = ref(false)

    let protocalTVL = ref(0)
    let deposits = ref(0)
    let withdraws = ref(0)
    let shares = ref(0)
    let maxToWithdraw = ref(0)
    let maxToWithdraw_display = ref(0)
    let profits = ref(0)
    let depositESTShares = ref(0)
    let withdrawESTShares = ref(0)
    let withdrawESTValues = ref(0)
    let inputTimeout = ref(null)
    let minDeposit = ref(0)
    let maxDeposit = ref(0)

    let depositError = ref('');
    let withdrawError = ref('');

    let selectedOperation = ref(null)
    // Set default values for deposit and withdraw amounts
    let depositAmount = ref(null)
    let withdrawAmount = ref(null)
    let withdrawType = ref('SHARE')

    // Toggle between Deposit and Withdraw, allowing deselection
    const toggleOperation = (operation) => {
      if (selectedOperation.value === operation) {
        selectedOperation.value = null // Deselect if the same button clicked again
        depositESTShares.value = 0
        withdrawESTShares.value = 0
        withdrawESTValues.value = 0
      } else {
        selectedOperation.value = operation
        resetAmounts() // Reset amounts when switching between Deposit/Withdraw
      }
    }

    const resetAmounts = () => {
      // Reset to default values
      depositAmount.value = null
      withdrawAmount.value = null
      depositESTShares.value = 0
      withdrawESTShares.value = 0
      withdrawESTValues.value = 0
      depositError.value = ''
      withdrawError.value = ''
    }

    // Handle input during typing
    const handleInput = (field) => {
      let value = field === 'depositAmount' ? depositAmount.value : withdrawAmount.value

      // Clear the previous timeout if input is made before 1 second has passed
      if (inputTimeout.value) {
        clearTimeout(inputTimeout)
      }

      // Set a new timeout to trigger fetchPreviewConvertToShares after 1 second
      if (field === 'depositAmount') {
        if (isNaN(value) || value < minDeposit.value) {
          depositError.value = `Minimum deposit is ${minDeposit.value}`;
        } else if (value > maxDeposit.value) {
          depositError.value = `Maximum deposit is ${maxDeposit.value}`;
        } else {
          depositError.value = ''; // Clear error
          depositAmount.value = parseFloat(value).toFixed(6);
        }
        if (depositError.value === '') {
          inputTimeout = setTimeout(async () => {
            try {
              const result = await fetchPreviewConvertToShares(modal, ethers.BigNumber.from(depositAmount.value * usdtDecimal.value))
              depositESTShares.value = result
            } catch (error) {
              console.log('Error fetching estimated shares:', error)
            }
          }, 1000)
        }
      }

      if (field === 'withdrawAmount' && withdrawType.value === 'USDT') {
        value = parseFloat(value).toFixed(6)
        if (isNaN(value) || value < 0.01) {
          withdrawError.value = 'Minimum USDT to withdraw is 0.01';
        } else if (ethers.BigNumber.from(value * usdtDecimal.value).gt(maxToWithdraw.value)) {
          withdrawError.value = `Maximum USDT to withdraw is ${parseFloat(maxToWithdraw.value / usdtDecimal.value).toFixed(6)}`;
        } else {
          withdrawError.value = ''; // Clear error
          withdrawAmount.value = parseFloat(value).toFixed(6); // Ensure 2 decimal places
        }
        if (withdrawError.value === '') {
          inputTimeout = setTimeout(async () => {
            try {
              const result = await fetchPreviewConvertToShares(modal, ethers.BigNumber.from(withdrawAmount.value * usdtDecimal.value))
              withdrawESTShares.value = result
            } catch (error) {
              console.log('Error fetching estimated shares:', error)
            }
          }, 1000)
        }
      }

      if (field === 'withdrawAmount' && withdrawType.value === 'SHARE') {
        value = parseInt(value);
        if (isNaN(value) || value < 1) {
          withdrawError.value = 'Minimum shares to withdraw is 1';
        } else if (value > shares.value) {
          withdrawError.value = `Maximum shares to withdraw is ${shares.value}`;
        } else {
          withdrawError.value = ''; // Clear error
          withdrawAmount.value = Number(value);
        }
        if (withdrawError.value === '') {
          inputTimeout = setTimeout(async () => {
            try {
              let result = await fetchPreviewConvertToAssets(modal, ethers.BigNumber.from(withdrawAmount.value))
              withdrawESTValues.value = parseFloat(result.toNumber() / usdtDecimal.value).toFixed(6)
            } catch (error) {
              console.log('Error fetching estimated shares:', error)
            }
          }, 1000)
        }
      }
      if (value === '') return
    }

    const setLiquidityInfo = async () => {
      try {
        console.log("setLiquidityInfo triggered")
        let liquidityInfo_ = liquidityInfo.value
        protocalTVL.value = liquidityInfo_.TotalDeposits.div(ethers.BigNumber.from(usdtDecimal.value))
        deposits.value = parseFloat(liquidityInfo_.Deposits.toNumber() / usdtDecimal.value).toFixed(2)
        withdraws.value = parseFloat(liquidityInfo_.Withdraws.toNumber() / usdtDecimal.value).toFixed(2)
        shares.value = liquidityInfo_.Shares
        maxToWithdraw.value = liquidityInfo_.MaxWithdraws
        maxToWithdraw_display.value = parseFloat(liquidityInfo_.MaxWithdraws.toNumber() / usdtDecimal.value).toFixed(2)
        let sharesToAssets = await fetchPreviewConvertToAssets(modal, liquidityInfo_.Shares)
        profits.value = liquidityInfo_.Withdraws.add(sharesToAssets).sub(liquidityInfo_.Deposits).div(ethers.BigNumber.from(usdtDecimal.value))
        minDeposit.value = liquidityInfo_.MinDeposits.div(ethers.BigNumber.from(usdtDecimal.value))
        maxDeposit.value = liquidityInfo_.MaxDeposits.div(ethers.BigNumber.from(usdtDecimal.value))
      } catch (error) {
        console.log('setLiquidityInfo error: ', error)
      }
    }

    const confirmDeposit = async () => {
      try {
        let _inputUsdt = ethers.BigNumber.from(Number(depositAmount.value)).mul(ethers.BigNumber.from(usdtDecimal.value))
        let _allowance = await allowance(modal)
        if (_allowance.lt(_inputUsdt)) {
            try {
              startTransaction(root.value, 'Approve')
              const [approveTx, err] = await approve(modal, _inputUsdt.mul(ethers.BigNumber.from("12")).div(ethers.BigNumber.from("10")), false)
              if (approveTx) {
                await approveTx.wait()
                completeTransaction(root.value, approveTx.hash)
              }
              else {
                failTransaction(root.value, err)
                return
              }
            } catch (error) {
              console.log('Transaction failed:', error)
              failTransaction(root.value, error.message)
            }
        }
        startTransaction(root.value, 'Deposit')
        let depositTx = await deposit(modal, _inputUsdt)
        let tx = depositTx[0]
        let err = depositTx[1]
        if (tx) {
          await tx.wait()
          await store.dispatch('fetchLiquidityInfo', { p_modal: modal })
          await setLiquidityInfo()
          console.log('Deposit Successfully! TX Hash: ' + tx.hash)
          completeTransaction(root.value, tx.hash)
        } else {
          console.log('null tx error: ', err)
          failTransaction(root.value, err)
        }
      } catch (error) {
        console.log('confirmDeposit error: ', error)
      } finally {
        resetAmounts()
      }
    }

    const confirmWithdraw = async () => {
      try {
        let _inputValue = withdrawType.value === 'USDT' ?
          ethers.BigNumber.from(withdrawAmount.value * usdtDecimal.value) :
          ethers.BigNumber.from(withdrawAmount.value)

        startTransaction(root.value, 'Withdraw ' + withdrawType.value)
        let withdrawTx = withdrawType.value === 'USDT' ?
          await withdraw(modal, _inputValue) :
          await redeem(modal, _inputValue)
        let tx = withdrawTx[0]
        let err = withdrawTx[1]
        if (tx) {
          await tx.wait()
          await store.dispatch('fetchLiquidityInfo', { p_modal: modal })
          await setLiquidityInfo()
          console.log('Withdraw Successfully! TX Hash: ' + tx.hash)
          completeTransaction(root.value, tx.hash)
        } else {
          console.log('null tx error: ', err)
          failTransaction(root.value, err)
        }
      } catch (error) {
        console.log('confirmWithdraw error: ', error)
      } finally {
        resetAmounts()
      }
    }

    watch(liquidityInfo, setLiquidityInfo, { immediate: true })

    // watch(
    //   () => [store.getters.liquidityInfo],
    //   ([newLiquidityInfo]) => {
    //     liquidityInfo.value = newLiquidityInfo
    //   },
    //   { immediate: true }
    // )

    onMounted(async () => {
      isMounting.value = true
      root.value = getCurrentInstance().proxy.$root

      if (!homePageParams.value) {
        await store.dispatch('fetchHomePageInfo', { p_modal: modal })
      }

      if (!store.getters.isLiquidityInfoLoaded) {
        await store.dispatch('fetchLiquidityInfo', { p_modal: modal })
        // liquidityInfo = computed(() => store.getters.liquidityInfo)
      }
      // await setLiquidityInfo()
      isMounting.value = false
    })

    return {
      root,
      modal,
      selectedOperation,
      depositAmount,
      withdrawAmount,
      withdrawType,
      usdtDecimal,
      toggleOperation,
      handleInput,

      protocalTVL,
      deposits,
      withdraws,
      shares,
      maxToWithdraw,
      maxToWithdraw_display,
      profits,
      confirmDeposit,
      confirmWithdraw,
      isMounting,
      depositESTShares,
      withdrawESTShares,
      withdrawESTValues,
      minDeposit,
      maxDeposit,
      depositError,
      withdrawError,
    }
  },
}
</script>