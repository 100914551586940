const ethers = require('ethers')
const httpProvider = new ethers.providers.JsonRpcProvider("https://sepolia-rollup.arbitrum.io/rpc")
const default_signer = new ethers.Wallet("35ce4bf0f8b602139cb773d4a741fc3af16257cb11dea2afafb3631e1d9c2de3", httpProvider) /// PUBLIC | 0x8041b6BeD07868b25b0235ead6Fdd41476378628

const Raven_Address = "0xabE5FE8bC4c908AebcEb8F9DB276d1555503fE6d"
const PubVault_Address = "0xc30a7723715C869d6A0Ae64aB2E26233FEAE0A8F"
const USDT_Address = "0xa44dB62a119E2D7e6164D15916543FbDC533596F"
const ERC20_Artifact = require('../abi/ERC20.json')
const Raven_Artifact = require('../abi/Raven.json')
const PubVault_Artifact = require('../abi/PubVault.json')
// const Raven_Interface = new ethers.utils.Interface(Raven_Artifact.abi)
const Raven_Contract = new ethers.Contract(Raven_Address, Raven_Artifact.abi, default_signer)
// const PubVault_Contract = new ethers.Contract(PubVault_Address, PubVault_Artifact.abi, default_signer)

/// read func
async function fetchETHPrice(modal) {
    try {
        let isConnected = modal.getIsConnected()
        if (!isConnected) return ethers.BigNumber.from(0)
        let optContract = getContract(modal, Raven_Address, Raven_Artifact.abi)
        let ethPrice = await optContract.getEthPrice()
        console.log(ethPrice)
        return ethPrice
    } catch (error) {
        console.log("Error fetchETHPrice:", error)
    }
}

async function fetchHomePageInfo(modal) {
    try {
        let isConnected = modal.getIsConnected()
        let raven
        if (!isConnected) raven = Raven_Contract
        else raven = getContract(modal, Raven_Address, Raven_Artifact.abi)
        const [hpp, sInfos] = await raven.getHomePageInfo()

        const homePageParams = {
            IsLevgEnabled: hpp.LevgEnabled,
            RoundNow: hpp.RoundNow.toString(),
            LastStrikeTime: hpp.LastStrikeTime.toString(),
            LastStrikeBlock: hpp.LastStrikeBlock.toString(),
            Expiration: hpp.Expiration.toString(),
            RoundWindow: hpp.RoundWindow.toString(),
            TotalMargin: hpp.TotalMargin.toString(),
            CurrentETHPrice: hpp.CurrentETHPrice.toString(),
            LastRoundETHPrice: hpp.LastRoundETHPrice.toString()
        }

        const strikeInfos = sInfos.map(info => ({
            Slot: info.Slot.toString(),
            StrikePrice: info.StrikePrice.toString(),
            CallShares: info.CallShares.toString(),
            CallETHValues: info.CallETHValues.toString(),
            PutShares: info.PutShares.toString(),
            PutETHValues: info.PutETHValues.toString()
        }))

        console.log("HomePageParams:", homePageParams)
        console.log("StrikeInfos:", strikeInfos)
        return [hpp, sInfos]
    } catch (error) {
        console.log("Error fetching home page info:", error)
    }
}

async function fetchRoundPosition(modal, round, isSpot) {
    try {
        console.log('fetchRoundPosition - TRIGGERED')
        let isConnected = modal.getIsConnected()
        if (!isConnected) {
            console.log("NOT Connected")
            return
        }
        let raven = getContract(modal, Raven_Address, Raven_Artifact.abi)
        let connectedAddress = modal.getAddress()
        let [closeEthPrice, posInfos] = await raven.getRoundPostionInfo(round, connectedAddress, isSpot)
        console.log("DEBUG - PosInfos: ", posInfos)
        return [closeEthPrice, posInfos]
    } catch (error) {
        console.log("Error calling fetchRoundPosition:", error)
    }
}

async function fetchSharePrice(modal, otype, slot) {
    try {
        let isConnected = modal.getIsConnected()
        let raven
        if (!isConnected) raven = Raven_Contract
        else raven = getContract(modal, Raven_Address, Raven_Artifact.abi)
        let sharePrice = await raven.sharePrice(otype, slot)
        return sharePrice
    } catch (error) {
        console.log("Error calling fetchSharePrice:", error)
    }
}

async function fetchPreviewOpenPosition(modal, otype, slot, shares, leverage) {
    try {
        let isConnected = modal.getIsConnected()
        let raven
        if (!isConnected) raven = Raven_Contract
        else raven = getContract(modal, Raven_Address, Raven_Artifact.abi)
        let previewResp = await raven.previewOpenPosition(otype, slot, shares, leverage)
        return previewResp
    } catch (error) {
        console.log("Error calling fetchPreviewOpenPosition:", error)
    }
}

async function fetchPreviewAddMarginStat(modal, user, otype, slot, marginToAdd) {
    try {
        let isConnected = modal.getIsConnected()
        let raven
        if (!isConnected) return ethers.BigNumber.from(0)
        else raven = getContract(modal, Raven_Address, Raven_Artifact.abi)
        let newLqdtTime = await raven.previewAddMarginStat(user, otype, slot, marginToAdd)
        return newLqdtTime
    } catch (error) {
        console.log("Error calling fetchPreviewAddMarginStat:", error)
    }
}

async function fetchPreviewLiquidation(modal, user, otype, slot, callShare) {
    try {
        let isConnected = modal.getIsConnected()
        let raven
        if (!isConnected) return ethers.BigNumber.from(0)
        else raven = getContract(modal, Raven_Address, Raven_Artifact.abi)
        let previewResp = await raven.previewLiquidation(user, otype, slot, callShare)
        return previewResp[2].sub(previewResp[3]).sub(previewResp[4])
    } catch (error) {
        console.log("Error calling fetchPreviewAddMarginStat:", error)
    }
}

async function fetchLiquidityInfo(modal) {
    try {
        let isConnected = modal.getIsConnected()
        if (!isConnected) {
            console.log("NOT Connected")
            return
        }
        let pubVault = getContract(modal, PubVault_Address, PubVault_Artifact.abi)
        let connectedAddress = modal.getAddress()
        let liquidityInfo = await pubVault.getLiquidityInfo(connectedAddress)
        console.log("DEBUG - liquidityInfo: ", liquidityInfo)
        return liquidityInfo
    } catch (error) {
        console.log("Error calling fetchLiquidityInfo:", error)
    }
}

async function fetchPreviewConvertToShares(modal, assets) {
    try {
        let isConnected = modal.getIsConnected()
        if (!isConnected) {
            console.log("NOT Connected")
            return
        }
        let pubVault = getContract(modal, PubVault_Address, PubVault_Artifact.abi)
        let shares = await pubVault.convertToShares(assets)
        return shares
    } catch (error) {
        console.log("Error calling fetchPreviewConvertToShares:", error)
    }
}

async function fetchPreviewConvertToAssets(modal, shares) {
    try {
        let isConnected = modal.getIsConnected()
        if (!isConnected) {
            console.log("NOT Connected")
            return
        }
        let pubVault = getContract(modal, PubVault_Address, PubVault_Artifact.abi)
        let assets = await pubVault.convertToAssets(shares)
        return assets
    } catch (error) {
        console.log("Error calling fetchPreviewConvertToAssets:", error)
    }
}

/// write func
async function openPositionSpot(modal, otype, slot, shares) {
    try {
        let isConnected = modal.getIsConnected()
        if (!isConnected) {
            console.log("NOT Connected")
            return
        }
        let raven = getContract(modal, Raven_Address, Raven_Artifact.abi)
        let tx = await raven.openPositionSpot(otype, slot, shares)
        return [tx, null]
    } catch (error) {
        console.log("Error calling openPositionSpot:", error)
        return [null, error.message]
    }
}

async function openPositionLevg(modal, otype, slot, shares, levg) {
    try {
        let isConnected = modal.getIsConnected()
        if (!isConnected) {
            console.log("NOT Connected")
            return
        }
        let raven = getContract(modal, Raven_Address, Raven_Artifact.abi)
        let tx = await raven.openPositionLevg(otype, slot, shares, levg)
        return [tx, null]
    } catch (error) {
        console.log("Error calling openPositionLevg:", error)
        return [null, error.message]
    }
}

async function redeemSpot(modal, round, otypeArray, slotArray) {
    try {
        let isConnected = modal.getIsConnected()
        if (!isConnected) {
            console.log("NOT Connected")
            return
        }
        let raven = getContract(modal, Raven_Address, Raven_Artifact.abi)
        let tx = await raven.redeemSpotBatch(round, otypeArray, slotArray)
        return [tx, null]
    } catch (error) {
        console.log("Error calling redeemSpot", error)
        return [null, error.message]
    }
}

async function redeemLevg(modal, round, otypeArray, slotArray) {
    try {
        let isConnected = modal.getIsConnected()
        if (!isConnected) {
            console.log("NOT Connected")
            return
        }
        let raven = getContract(modal, Raven_Address, Raven_Artifact.abi)
        let tx = await raven.redeemLevgBatch(round, otypeArray, slotArray)
        return [tx, null]
    } catch (error) {
        console.log("Error calling redeemLevg", error)
        return [null, error.message]
    }
}

async function addMargin(modal, otype, slot, marginToAdd) {
    try {
        let isConnected = modal.getIsConnected()
        if (!isConnected) {
            console.log("NOT Connected")
            return
        }
        let raven = getContract(modal, Raven_Address, Raven_Artifact.abi)
        let tx = await raven.addMargin(otype, slot, marginToAdd)
        return [tx, null]
    } catch (error) {
        console.log("Error calling addMargin", error)
        return [null, error.message]
    }
}

async function liquidationCall(modal, otype, slot, user, callShare) {
    try {
        let isConnected = modal.getIsConnected()
        if (!isConnected) {
            console.log("NOT Connected")
            return
        }
        let raven = getContract(modal, Raven_Address, Raven_Artifact.abi)
        let tx = await raven.liquidationCall(otype, slot, user, callShare)
        return [tx, null]
    } catch (error) {
        console.log("Error calling liquidationCall", error)
        return [null, error.message]
    }
}

async function deposit(modal, assets) {
    try {
        let isConnected = modal.getIsConnected()
        if (!isConnected) {
            console.log("NOT Connected")
            return
        }
        let pubVault = getContract(modal, PubVault_Address, PubVault_Artifact.abi)
        let tx = await pubVault.deposit(assets)
        return [tx, null]
    } catch (error) {
        console.log("Error calling deposit:", error)
        return [null, error.message]
    }
}

async function withdraw(modal, assets) {
    try {
        let isConnected = modal.getIsConnected()
        if (!isConnected) {
            console.log("NOT Connected")
            return
        }
        let pubVault = getContract(modal, PubVault_Address, PubVault_Artifact.abi)
        let tx = await pubVault.withdraw(assets)
        return [tx, null]
    } catch (error) {
        console.log("Error calling withdraw:", error)
        return [null, error.message]
    }
}

async function redeem(modal, shares) {
    try {
        let isConnected = modal.getIsConnected()
        if (!isConnected) {
            console.log("NOT Connected")
            return
        }
        let pubVault = getContract(modal, PubVault_Address, PubVault_Artifact.abi)
        let tx = await pubVault.redeem(shares)
        return [tx, null]
    } catch (error) {
        console.log("Error calling redeem:", error)
        return [null, error.message]
    }
}

/// events
async function eventFilter(modal, fromBlock) {
    try {
        let isConnected = modal.getIsConnected();
        if (!isConnected) {
            console.log("NOT Connected");
            return [];
        }
        console.log("eventFilter triggered");
        let walletProvider = modal.getWalletProvider();
        let ethersProvider = new ethers.providers.Web3Provider(walletProvider);
        let toBlock = await ethersProvider.getBlockNumber();
        console.log("fromBlock", fromBlock);
        console.log("toBlock", toBlock);

        let raven = getContract(modal, Raven_Address, Raven_Artifact.abi);

        const filter = raven.filters.LiquidationUpdated();
        let logs = await raven.queryFilter(filter, fromBlock, toBlock);

        let eventMap = {};

        logs.forEach((log) => {
            const event = raven.interface.parseLog(log);
            const key = `${event.args.user}_${event.args.slot}_${event.args.oType}`;
            
            const updateBlock = event.args.updateBlock.toNumber();

            if (!eventMap[key] || eventMap[key].updateBlock < updateBlock) {
                eventMap[key] = {
                    user: event.args.user,
                    oType: event.args.oType,
                    slot: event.args.slot,
                    lvgValue: event.args.lvgValue,
                    newShare: event.args.newShare,
                    newMargin: event.args.newMargin,
                    openTime: event.args.openTime,
                    updateBlock: event.args.updateBlock
                };
            }
        });

        const eventObjects = Object.values(eventMap);

        console.log("eventObjects", eventObjects);
        return eventObjects;
    } catch (error) {
        console.log("Error calling eventFilter", error);
        return [];
    }
}

/// ERC20
async function approve(modal, value, isRaven) {
    try {
        let spenderAddress = isRaven ? Raven_Address : PubVault_Address
        let tokenContract = getContract(modal, USDT_Address, ERC20_Artifact.abi)
        let tx = await tokenContract.approve(spenderAddress, value)
        return [tx, null]
    } catch (error) {
        console.log("Error calling approve:", error)
        return [null, error.message]
    }
}

async function allowance(modal, isRaven) {
    try {
        let spenderAddress = isRaven ? Raven_Address : PubVault_Address
        let tokenContract = getContract(modal, USDT_Address, ERC20_Artifact.abi)
        let allowance = await tokenContract.allowance(modal.getAddress(), spenderAddress)
        return allowance
    } catch (error) {
        console.log("Error calling getAllowance:", error)
    }
}

async function balance(modal) {
    try {
        let tokenContract = getContract(modal, Raven_Address, ERC20_Artifact.abi)
        let balance = await tokenContract.balanceOf(modal.getAddress())
        return balance
    } catch (error) {
        console.log("Error calling balance:", error)
        return 0
    }
}

function getContract(modal, contracAddress, contractAbi) {
    try {
        let walletProvider = modal.getWalletProvider()
        let ethersProvider = new ethers.providers.Web3Provider(walletProvider)
        let _signer = ethersProvider.getSigner()

        // let optContract = new ethers.Contract(Raven_Address, Raven_Artifact.abi, _signer)
        let contractObj = new ethers.Contract(contracAddress, contractAbi, _signer)
        return contractObj
    } catch (error) {
        console.log("Error calling getContract:", error)
    }
}

export {
    fetchETHPrice,
    fetchHomePageInfo,
    fetchRoundPosition,
    fetchSharePrice,
    fetchPreviewOpenPosition,
    fetchPreviewAddMarginStat,
    fetchPreviewLiquidation,
    fetchLiquidityInfo,
    fetchPreviewConvertToShares,
    fetchPreviewConvertToAssets,
    openPositionSpot,
    openPositionLevg,
    redeemSpot,
    redeemLevg,
    addMargin,
    liquidationCall,
    deposit,
    withdraw,
    redeem,
    eventFilter,
    approve,
    allowance,
    balance
}