import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import MyPositions from '../views/MyPositions.vue';
import SpotPos from '../views/SpotPos.vue';
import LevgPos from '../views/LevgPos.vue';
import LiquidationPage from '../views/LiquidationPage.vue';
import LiquidityPage from '@/views/LiquidityPage.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
  },
  {
    path: '/positions',
    component: MyPositions,
    children: [
      {
        path: '',
        name: '',
        component: SpotPos,
      },
      {
        path: 'spot',
        name: 'Spot',
        component: SpotPos,
      },
      {
        path: 'leverage',
        name: 'Leverage',
        component: LevgPos,
      },
    ],
  },
  {
    path: '/liquidation',
    name: 'LiquidationPage',
    component: LiquidationPage,
  },
  {
    path: '/liquidity',
    name: 'LiquidityPage',
    component: LiquidityPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
