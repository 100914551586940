const ACKNOWLEDGE_VALIDITY = 24 * 60 * 60 * 1000 // 24 hours

export const checkAcknowledgedAddress = async (address) => {
    try {
        console.log('checkAcknowledgedAddress trigger')
        const storedData = localStorage.getItem('acknowledgedAddresses')
        if (!storedData) return false

        const acknowledgedAddresses = JSON.parse(storedData)
        const addressData = acknowledgedAddresses[address]
        if (!addressData) return false

        const now = Date.now()
        if (now - addressData.timestamp < ACKNOWLEDGE_VALIDITY) {
            return true
        } else {
            delete acknowledgedAddresses[address]
            localStorage.setItem('acknowledgedAddresses', JSON.stringify(acknowledgedAddresses))
            return false
        }
    } catch (error) {
        console.log('checkAcknowledgedAddress failedd:', error)
        return false
    }
}

export const acknowledge = async (address) => {
    try {
        console.log('acknowledge trigger')
        if (!address) {
            return false
        }
        const acknowledgedAddresses = JSON.parse(localStorage.getItem('acknowledgedAddresses') || '{}')
        acknowledgedAddresses[address] = { timestamp: Date.now() }
        localStorage.setItem('acknowledgedAddresses', JSON.stringify(acknowledgedAddresses))
        return true
    } catch (error) {
        console.log('acknowledge failedd:', error)
        return false
    }
}