<template>
  <div>
    <nav class="center-nav">
      <router-link class="position"
          to="/positions/spot" 
          :class="{ 'active-link': isSpotPosition }"
        >Spot</router-link>
      <router-link class="position" to="/positions/leverage" exact-active-class="active-link">Leverage</router-link>
    </nav>
    <router-view></router-view>
  </div>
</template>

<script>
import '../assets/styles/global-style.css'

export default {
  name: 'MyPositions',
  computed: {
    isSpotPosition() {
      return (this.$route.path.endsWith('/positions') || this.$route.path.endsWith('/spot'))
    }
  },
}
</script>
