<template>
  <div class="positions-container">
    <h1 class="title">Liquidation</h1>
    <div class="info-section">
      <div class="refresh-section">
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
        <!-- <button class="refresh-button" @click="refreshData" :disabled="isRefreshing || isRefreshButtonDisabled">
          Refresh
        </button> -->
        <button type="submit" @click="refreshData" :disabled="isRefreshing || isRefreshButtonDisabled" style="border-radius: 12px; background-color: #13151b; margin: 5px;">
          <i class="fa fa-refresh" style="color: white;"></i>
        </button>
      </div>
    </div>

    <div v-if="isRefreshing || isMounting" class="search-spinner"></div>

    <table class="position-table">
      <thead>
        <tr>
          <th>Position ID</th>
          <th>Shares</th>
          <th>Max Liquidation Value</th>
          <th>Liquidation Count Down</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="lqdtInfo in liquidationInfos" :key="lqdtInfo.positionId">
          <td>{{ lqdtInfo.positionId }}</td>
          <td>{{ lqdtInfo.shares }}</td>
          <td>{{ lqdtInfo.maxLqdtValue }}</td>
          <td>{{ formatCountdown(lqdtInfo.expiration) }}</td>
          <td>
            <!-- <button class="lqdt-button" @click="handleLqdt(lqdtInfo)" :disabled="!isReadyForLiquidation(lqdtInfo.expiration)">
              Liquidate
            </button> -->
            <button class="open-position-button" 
              style="width: 100px !important; height: 40px !important; font-size: 12px !important; text-align: center !important; white-space: nowrap !important;" 
              @click="handleLqdt(lqdtInfo)" 
              :disabled="!isReadyForLiquidation(lqdtInfo.expiration)"
              :class="{ disabled: !isReadyForLiquidation(lqdtInfo.expiration)}">
              Liquidate
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- A window for handling liquidation -->
  <div v-if="showDialog" class="overlay">
    <div class="dialog">
      <div class="dialog-content">
        <h2>Confirm Liquidation</h2>
        <p>Position ID: {{ selectedLqdtInfo.positionId }}</p>
        <p>Liquiation Shares: {{ selectedLqdtInfo.shares }}</p>
        <p>My Shares: {{ myRavenShares }}</p>
        <div>
          <label for="lqdtAmount">Shares to Liquidate: </label>
          <input type="number" id="lqdtAmount" v-model.number="lqdtAmount" min="1" :max="selectedLqdtInfo.shares"
            @input="validateLqdtAmount" />
        </div>
        <span v-if="lqdtAmountError" class="error">{{ lqdtAmountError }}</span>

        <div v-if="isLoading" class="spinner"></div>

        <div v-if="liquidatedAmount" class="input-controls">
          <label>Est. USDT Receive: <span>${{ liquidatedAmount }}</span></label>
        </div>
        <div class="buttons">
          <button class="buttons-confirm" @click="confirmLqdt" :disabled="!isValidLqdtAmount">Confirm</button>
          <button class="buttons-cancel" @click="cancelLqdt">Cancel</button>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import { ref, inject, onMounted, computed, watch, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { liquidationCall, fetchPreviewLiquidation, balance } from '../assets/scripts/web3utils'
import { startTransaction, completeTransaction, failTransaction } from '../assets/scripts/bannerutils'
import { ethers } from 'ethers'
import '../assets/styles/global-style.css'

export default {
  setup() {
    const store = useStore()
    const modal = inject('web3modal')
    const root = ref(null)

    let usdtDecimal = computed(() => store.getters.USDT_DECIMAL)
    let homePageParams = computed(() => store.getters.homePageParams)
    let slotStrikeMap = computed(() => store.getters.strikeInfoMap)
    let rawLqdtPositions = computed(() => store.getters.lqdtPosition)
    let lastStrikeBlock = ref()
    let liquidationInfos = ref([])

    let showDialog = ref(false)
    let selectedLqdtInfo = ref({})
    let lqdtAmount = ref(0)
    let lqdtAmountError = ref('')
    let isValidLqdtAmount = ref(false)

    let isLoading = ref(false)
    let liquidatedAmount = ref('')
    let myRavenShares = ref(0)

    let isMounting = ref(false)
    let isRefreshing = ref(false)
    let isRefreshButtonDisabled = ref(false)

    const updateData = () => {
      if (homePageParams.value && homePageParams.value.length > 0) {
        lastStrikeBlock.value = homePageParams.value.LastStrikeBlock.toNumber()
      }
    }

    watch(homePageParams, updateData, { immediate: true })

    const formatCountdown = (expiration) => {
      const now = Math.floor(Date.now() / 1000)
      const diff = expiration - now

      if (diff <= 0) return "Ready For Liquiation"

      const hours = Math.floor(diff / 3600)
      const minutes = Math.floor((diff % 3600) / 60)
      const seconds = diff % 60

      return `${hours}h ${minutes}m ${seconds}s`
    }

    const isReadyForLiquidation = (expiration) => {
      const now = Math.floor(Date.now() / 1000)
      return expiration <= now
    }

    watch(lqdtAmount, async (newValue) => {
      if (newValue > 0) {
        liquidatedAmount.value = ''
        isLoading.value = true

        setTimeout(async () => {
          try {
            if (!modal.getAddress()) {
              isLoading.value = false
              return
            }

            let _type = selectedLqdtInfo.value.oType
            let slot = selectedLqdtInfo.value.slot
            let user = selectedLqdtInfo.value.user

            let result = await fetchPreviewLiquidation(modal, user, _type, slot, lqdtAmount.value)
            liquidatedAmount.value = parseFloat(result.toNumber() / usdtDecimal.value).toFixed(6)
          } catch (error) {
            console.log('Failed to fetch liquidated amount:', error)
          } finally {
            isLoading.value = false
          }
        }, 5000)
      }
    }, { immediate: false })

    const assembleData = (rawLqdtPositions) => {
      if (rawLqdtPositions.value.length > 0) {
        let posId = 0
        for (let i = 0; i < rawLqdtPositions.value.length; i++) {
          if (rawLqdtPositions.value[i].newShare.eq(ethers.BigNumber.from(0))) { continue }
          let strike = slotStrikeMap.value.get(rawLqdtPositions.value[i].slot)
          let currentETH = homePageParams.value.CurrentETHPrice
          if ((rawLqdtPositions.value[i].oType == 0 && currentETH.lt(strike)) || (rawLqdtPositions.value[i].oType == 1 && currentETH.gt(strike))) {
            let expiration
            expiration =
              rawLqdtPositions.value[i].lvgValue.eq(ethers.BigNumber.from(0)) ? 0 :
                rawLqdtPositions.value[i].openTime.add(ethers.BigNumber.from(homePageParams.value.Expiration).mul(rawLqdtPositions.value[i].newMargin).div(rawLqdtPositions.value[i].lvgValue))
            liquidationInfos.value.push({
              positionId: posId,
              shares: rawLqdtPositions.value[i].newShare,
              maxLqdtValue: parseFloat(Number(rawLqdtPositions.value[i].newMargin) / usdtDecimal.value).toFixed(2),
              expiration: expiration,
              oType: rawLqdtPositions.value[i].oType,
              user: rawLqdtPositions.value[i].user,
              slot: rawLqdtPositions.value[i].slot
            })
            posId++
          }
        }
      }
    }

    const validateLqdtAmount = () => {
      if (lqdtAmount.value < 1 || lqdtAmount.value > selectedLqdtInfo.value.shares || !Number.isInteger(lqdtAmount.value)) {
        lqdtAmountError.value = 'Invalid amount of shares'
        isValidLqdtAmount.value = false
      } else {
        lqdtAmountError.value = ''
        isValidLqdtAmount.value = true
      }
    }

    const handleLqdt = async (lqdtInfo) => {
      selectedLqdtInfo.value = lqdtInfo
      showDialog.value = true
      let myBalance = await balance(modal)
      myRavenShares.value = myBalance.div(ethers.BigNumber.from("1000000000000000000")).toString()
    }

    const confirmLqdt = async () => {
      try {
        if (isValidLqdtAmount.value) {
          startTransaction(root.value, 'LiquidationCall')
          let [tx, err] = await liquidationCall(modal, selectedLqdtInfo.value.oType, selectedLqdtInfo.value.slot, selectedLqdtInfo.value.user, lqdtAmount.value)
          if (tx) {
            await tx.wait()
            liquidationInfos.value = []
            await store.dispatch('fetchLqdtPosition', { p_modal: modal, p_fromBlock: lastStrikeBlock.value })
            rawLqdtPositions = computed(() => store.getters.lqdtPosition)
            assembleData(rawLqdtPositions)
            cancelLqdt()
            completeTransaction(root.value, tx.hash)
          } else {
            console.log('Liquidation Failed')
            failTransaction(root.value, err)
          }
        }
      } catch (error) {
        console.log('handleRedeemLevg error: ', error)
        failTransaction(root.value, error.message)
      }
    }

    const cancelLqdt = () => {
      showDialog.value = false
      liquidatedAmount.value = null
      lqdtAmount.value = 0 
      myRavenShares.value = 0
    }

    const refreshData = async () => {
      if (!lastStrikeBlock.value || lastStrikeBlock.value == 0) { return }
      isRefreshing.value = true
      await manualRefresh()
      isRefreshing.value = false
    }

    const manualRefresh = async () => {
      liquidationInfos.value = []
      await store.dispatch('fetchLqdtPosition', { p_modal: modal, p_fromBlock: lastStrikeBlock.value })
      rawLqdtPositions = computed(() => store.getters.lqdtPosition)
      assembleData(rawLqdtPositions)
    }

    onMounted(async () => {
      isMounting.value = true
      isRefreshButtonDisabled.value = true
      root.value = getCurrentInstance().proxy.$root

      if (!homePageParams.value) {
        await store.dispatch('fetchHomePageInfo', { p_modal: modal })
        homePageParams = computed(() => store.getters.homePageParams)
        slotStrikeMap = computed(() => store.getters.strikeInfoMap)
        usdtDecimal = computed(() => store.getters.USDT_DECIMAL)
      }

      if (!store.getters.isLqdtPosLoaded) {
        await store.dispatch('fetchLqdtPosition', { p_modal: modal, p_fromBlock: lastStrikeBlock.value })
        rawLqdtPositions = computed(() => store.getters.lqdtPosition)
      }
      assembleData(rawLqdtPositions)
      isRefreshButtonDisabled.value = false

      setInterval(() => {
        liquidationInfos.value = [...liquidationInfos.value]
      }, 1000)
      isMounting.value = false
    })

    return {
      liquidationInfos,
      showDialog,
      selectedLqdtInfo,
      lqdtAmount,
      lqdtAmountError,
      isValidLqdtAmount,
      liquidatedAmount,
      myRavenShares,
      isLoading,
      isMounting,
      isRefreshing,
      isRefreshButtonDisabled,
      formatCountdown,
      handleLqdt,
      confirmLqdt,
      cancelLqdt,
      validateLqdtAmount,
      isReadyForLiquidation,
      refreshData
    }
  },
}
</script>