// src/utils/transactionUtils.js

export const startTransaction = (root, name) => {
    root.$refs.transactionBanner.startTransaction(name)
}

export const completeTransaction = (root, hash) => {
    root.$refs.transactionBanner.completeTransaction(hash)
}

export const failTransaction = (root, error) => {
    root.$refs.transactionBanner.failTransaction(error)
}
