<template>
  <div id="app" class="homepage">
    <!-- Container A -->
    <div class="container-a">
      <div class="tradingview-widget-container">
        <SymbolOverview :options="{
          symbols: [
            [
              'BINANCE:ETHUSD|1D'
            ]
          ],
          chartOnly: false,
          width: '100%',
          height: '100%',
          locale: 'en',
          colorTheme: 'dark',
          autosize: true,
          showVolume: false,
          showMA: false,
          hideDateRanges: false,
          hideMarketStatus: true,
          hideSymbolLogo: true,
          scalePosition: 'right',
          scaleMode: 'Normal',
          fontFamily: 'Roboto, sans-serif',
          fontSize: 12,
          noTimeScale: false,
          valuesTracking: '1',
          changeMode: 'price-and-percent',
          chartType: 'area',
          headerFontSize: 'large',
          isTransparent: true,
          lineWidth: 2,
          lineType: 0,
          dateRanges: [
            '1d|1'
          ]
        }" />
      </div>

      <div class="sidebar">
        <!-- Buttons -->
        <div class="header-card">
          <button @click="selectOption('CALL')" :disabled="countdown === 'Closed'"
            :class="['call-button', { selected: selectedOption === 'CALL' }]">Calls</button>
          <button @click="selectOption('PUT')" :disabled="countdown === 'Closed'"
            :class="['put-button', { selected: selectedOption === 'PUT' }]">Puts</button>
        </div>

        <!-- Sidebar with essential details -->
        <div v-if="currentRound">
          <div class="card">
            <span>Round {{ currentRound }}</span>
            <span>
              🕒
              <!-- ⌛ -->
              <span v-if="!isRoundClosed">
                <span class="hours">{{ countdown.hours }}</span>:
                <span class="minutes">{{ countdown.minutes }}</span>:
                <span class="seconds">{{ countdown.seconds }}</span>
              </span>
              <span v-else class="closed">{{ countdown }}</span>
            </span>
          </div>
          <div class="divider"></div>
        </div>
        <div v-if="!currentRound">
          <div class="card">
            <label style="align-items: center;">Fetching Data ...</label>
            <div class="search-spinner-home" style="align-items: center;"></div>
          </div>
          <div class="divider"></div>
        </div>

        <div class="card">
          <label>Strike</label>
          <select v-model="selectedStrike" @change="getSharePrice" :disabled="!selectedOption" class="option-list">
            <option v-for="strike in strikes" :key="strike.slot" :value="strike">
              ETH ${{ strike.value }}
            </option>
          </select>
        </div>
        <div class="divider"></div>

        <!-- Leverage Section -->
        <div class="card">
          <div class="leverage-container">
            <label>Leverage</label>
            <div class="checkbox-wrapper-7">
              <input class="tgl tgl-ios" id="cb2-7" type="checkbox" v-model="leverageEnabled" @change="getMarginAndLqdt"
                :disabled="!selectedOption || !selectedStrike || !isLevgEnabled" 
                :enabled="selectedOption && selectedStrike && isLevgEnabled"
              />
              <label class="tgl-btn" for="cb2-7"></label>
            </div>
          </div>

          <div v-if="leverageEnabled" class="leverage-controls">
            <input type="number" v-model.number="leverageValue" @input="getMarginAndLqdt" min="2" max="100"
              class="small-input" />
            <span v-if="leverageError" class="error">{{ leverageError }}</span>
            <input type="range" class="accent" v-model.number="leverageValue" @input="getMarginAndLqdt" min="2"
              max="100" width="100px" />
          </div>
        </div>
        <div class="divider"></div>

        <!-- Input Section -->
        <div class="card">
          <label for="buyAmount">Shares</label>
          <div class="input-controls">
            <input type="number" id="buyAmount" v-model.number="buyAmount" @input="getTotalCost"
              :disabled="!selectedOption || !selectedStrike" min="1" step="1" class="small-input" />
            <!-- <span v-if="buyAmountError" class="error">{{ buyAmountError }}</span> -->
          </div>
        </div>
        <div class="divider"></div>

        <!-- Proceed Button, Trigger Menu A -->
        <div class="bottom-card">
          <label style="color: #5B5B5B;">Total Funds</label>
          <span>${{ totalMargin }}</span>
        </div>
        <div class="bottom-card">
          <label style="color: #5B5B5B;">Share Price</label>
          <span>${{ sharePrice }}</span>
        </div>
        <div class="bottom-card">
          <label style="color: #5B5B5B;">Margin</label>
          <span>${{ totalCost }}</span>
        </div>
        <div class="bottom-card">
          <label style="color: #5B5B5B;">Funds Borrowed</label>
          <span>${{ marginSpend }}</span>
        </div>
        <div class="bottom-card">
          <label style="color: #5B5B5B;">Liquidation</label>
          <span>{{ lqdtTime }}</span>
        </div>
        <div class="card">
          <button class="open-position-button" @click="showMenuA"
            :disabled="!selectedStrike || !selectedOption || !validateBuyAmount() || !validateLeverage()" :class="{
          disabled: !selectedStrike || !selectedOption || !validateBuyAmount() || !validateLeverage(),
          enabled: selectedStrike && selectedOption && validateBuyAmount() && validateLeverage()
        }">Open Position</button>
        </div>
      </div>
    </div>

    <!-- Container B -->
    <div class="container-b">
      <label>Share / Value Stat</label>
      <div class="chartJS-container">
        <canvas id="myChart"></canvas>
      </div>
    </div>

    <!-- Menu A (Initially Hidden) -->
    <div v-if="showMenu" class="overlay">
      <div class="dialog">
        <div class="dialog-content">
          <h2> Confirm Your Trade </h2>
          <p>Open Share: {{ buyAmount }}</p>
          <p v-if="leverageEnabled">Leverage: {{ leverageValue }}</p>
          <div v-if="isLoading" class="spinner"></div>
          <div v-if="sharePrice_display">
            <p>Share Price: {{ sharePrice_display }}</p>
          </div>
          <div v-if="usdtPayment_display">
            <p>USDT Payment: {{ usdtPayment_display }}</p>
          </div>
          <div v-if="lqdtTimePreview">
            <p>Preview Liquidation Time: {{ lqdtTimePreview }}</p>
          </div>
          <div class="buttons">
            <button class="buttons-confirm" @click="confirmSelection">Confirm</button>
            <button class="buttons-cancel" @click="closeMenuA">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject, onMounted, computed, watch, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { allowance, approve, openPositionSpot, openPositionLevg, fetchPreviewOpenPosition, fetchSharePrice } from '../assets/scripts/web3utils'
import { startTransaction, completeTransaction, failTransaction } from '../assets/scripts/bannerutils'
import { ethers } from 'ethers'
import { SymbolOverview } from "vue-tradingview-widgets"
import Chart from 'chart.js/auto'
import '../assets/styles/global-style.css'

export default {
  name: 'HomePage',
  components: {
    SymbolOverview,
  },
  setup() {
    const store = useStore()
    const modal = inject('web3modal')
    const root = ref(null)

    // Vars
    let ethDecimal = computed(() => store.getters.ETH_DECIMAL)
    let usdtDecimal = computed(() => store.getters.USDT_DECIMAL)
    let homePageParams = computed(() => store.getters.homePageParams)
    let strikeInfos = computed(() => store.getters.strikeInfos)
    let currentRound = ref()
    let lastStrike = ref()
    let nextStrike = ref()
    let lastStrikeBlock = ref()
    let currentETHPrice = ref()
    let lastStrikeETHPrice = ref()
    let totalMargin = ref()
    let countdown = ref('')
    let buyAmount = ref(1)
    let buyAmountError = ref('')
    let leverageEnabled = ref(false)
    let leverageValue = ref(2)
    let leverageError = ref('')
    let strikes = ref([])
    let selectedStrike = ref(null)
    let selectedOption = ref(null)
    let isLoading = ref(false)
    // New Add InfoTag
    let isRoundClosed = ref(false)
    let sharePrice = ref()
    let totalCost = ref()
    let marginSpend = ref()
    let lqdtTime = ref('n/a')
    let isLevgEnabled = ref(false)
    // Menu A
    const showMenu = ref(false)
    const sharePrice_display = ref(0)
    const usdtPayment_display = ref(0)
    const usdtPayment = ref(0)
    const lqdtTimePreview = ref('')

    // Chart Data
    const myChart = ref(null)

    const createChart = () => {
      try {
        const scaleFactor = 100
        const color_green = 'rgba(1, 202, 148, 0.95)'
        const color_green_1 = 'rgba(1, 202, 148, 0.55)'
        const color_red = 'rgba(241, 18, 121, 0.95)'
        const color_red_1 = 'rgba(241, 18, 121, 0.55)'
        const ctx = document.getElementById('myChart')
        const chartObj = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: strikeInfos.value.map(item => item.StrikePrice / ethDecimal.value),
            datasets: [
              {
                label: 'Call Shares',
                data: strikeInfos.value.map(item => item.CallShares.toNumber()),
                backgroundColor: color_green,
                barThickness: 30,
                stack: 'Stack 0',
              },
              {
                label: 'Call Values',
                data: strikeInfos.value.map(item => (Number(item.CallETHValues) / (scaleFactor * usdtDecimal.value)) * (-1)),
                backgroundColor: color_green_1,
                barThickness: 30,
                stack: 'Stack 0',
              },
              {
                label: 'Put Shares',
                data: strikeInfos.value.map(item => item.PutShares.toNumber()),
                backgroundColor: color_red,
                barThickness: 30,
                stack: 'Stack 1',
              },
              {
                label: 'Put Values',
                data: strikeInfos.value.map(item => (Number(item.PutETHValues) / (scaleFactor * usdtDecimal.value)) * (-1)),
                backgroundColor: color_red_1,
                barThickness: 30,
                stack: 'Stack 1',
              },
            ]
          },
          options: {
            responsive: true,
            scales: {
              x: {
                stacked: true,
                beginAtZero: true,
              },
              y: {
                stacked: true,
                beginAtZero: true,
                position: 'right',
                ticks: {
                  callback: function (value) {
                    if (value < 0) {
                      return Math.abs((value * scaleFactor).toFixed(2))
                    } else {
                      return value
                    }
                  }
                },
              }
            },
            plugins: {
              // title: {
              //   display: true,
              //   text: 'Share & Value',
              //   font: {
              //     family: 'Roboto',
              //     size: 20,
              //   },
              //   color: '#5B5B5B',
              // },
              legend: {
                display: false, 
              },
              tooltip: {
                callbacks: {
                  label: function (tooltipItem) {
                    let originalValue = tooltipItem.raw
                    if (tooltipItem.raw < 0) {
                      originalValue = (tooltipItem.raw * scaleFactor).toFixed(2) 
                    }
                    return `${tooltipItem.dataset.label}: ${Math.abs(originalValue)}`
                  }
                }
              }
            }
          }
        })
        myChart.value = chartObj
      } catch (error) {
        console.log("create chart error: ", error)
      }
    }

    const destroyChart = () => {
      try {
        if (myChart.value) { myChart.value.destroy() }
      } catch (error) {
        console.log("destroy chart error: ", error)
      }
    }

    const updateData = () => {
      if (homePageParams.value && homePageParams.value.length > 0) {
        currentRound.value = homePageParams.value.RoundNow.toNumber()
        lastStrikeETHPrice.value = parseFloat(homePageParams.value.LastRoundETHPrice.toNumber() / ethDecimal.value).toFixed(2)
        currentETHPrice.value = parseFloat(homePageParams.value.CurrentETHPrice.toNumber() / ethDecimal.value).toFixed(2)
        lastStrike.value = homePageParams.value.LastStrikeTime.toNumber()
        nextStrike.value = homePageParams.value.LastStrikeTime.toNumber() + homePageParams.value.Expiration.toNumber()
        lastStrikeBlock.value = homePageParams.value.LastStrikeBlock.toNumber()
        // totalMargin.value = homePageParams.value.TotalMargin.div(ethers.BigNumber.from(usdtDecimal.value)).toString()
        let totalMargin_raw = ethers.BigNumber.from(homePageParams.value.TotalMargin).div(ethers.BigNumber.from(usdtDecimal.value))
        totalMargin.value = totalMargin_raw > 10 ** 6 ? (totalMargin_raw / 10 ** 6).toFixed(2).toString() + 'M' : totalMargin_raw.toString()
        isLevgEnabled.value = homePageParams.value.LevgEnabled

        const formattedStrikes = strikeInfos.value.map(item => ({
          slot: Number(item.Slot),
          value: Number(item.StrikePrice) / 10 ** 8,
          callShares: Number(item.CallShares),
          callValues: Number(item.CallETHValues),
          putShares: Number(item.PutShares),
          putValues: Number(item.PutETHValues)
        }))

        strikes.value = formattedStrikes

        if (strikeInfos.value && strikeInfos.value.length > 0) {
          destroyChart()
          createChart()
        }
      }
    }

    watch([homePageParams, strikeInfos], updateData, { immediate: true })

    const updateCountdown = () => {
      if (!lastStrike.value) return
      const now = Math.floor(new Date().getTime() / 1000)
      const nextRoundStart = lastStrike.value + homePageParams.value.RoundWindow.toNumber()
      const diff = nextRoundStart - now
      if (diff > 0) {
        const hours = Math.floor(diff / (60 * 60))
        const minutes = Math.floor((diff % (60 * 60)) / 60)
        const seconds = Math.floor(diff % 60)

        const formattedHours = String(hours).padStart(2, '0')
        const formattedMinutes = String(minutes).padStart(2, '0')
        const formattedSeconds = String(seconds).padStart(2, '0')

        countdown.value = { hours: formattedHours, minutes: formattedMinutes, seconds: formattedSeconds }
        isRoundClosed.value = false
      } else {
        countdown.value = 'Closed'
        isRoundClosed.value = true
      }
    }

    const formatCountdown = (expiration) => {
      const now = Math.floor(Date.now() / 1000)
      const diff = expiration - now

      if (diff <= 0) return "Ready For Liquiation"

      const hours = Math.floor(diff / 3600)
      const minutes = Math.floor((diff % 3600) / 60)
      const seconds = diff % 60

      // return `${hours}h ${minutes}m ${seconds}s`
      const formattedHours = String(hours).padStart(2, '0')
      const formattedMinutes = String(minutes).padStart(2, '0')
      const formattedSeconds = String(seconds).padStart(2, '0')
      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
    }

    const selectOption = (option) => {
      if (selectedOption.value === option) {
        selectedOption.value = null
        cleanTag()
      } else {
        if (selectedOption.value && selectedOption.value != option) {
          cleanTag()
        }
        selectedOption.value = option
      }
    }

    const cleanTag = () => {
      selectedStrike.value = null
      leverageEnabled.value = false
      leverageValue.value = 2
      buyAmount.value = 1
      sharePrice.value = 0
      totalCost.value = 0
      marginSpend.value = 0
      lqdtTime.value = 'n/a'
    }

    const validateBuyAmount = () => {
      if (buyAmount.value < 1 || !Number.isInteger(buyAmount.value)) {
        buyAmountError.value = 'Amount must be greater than 1'
        totalCost.value = 0
        return false
      } else {
        buyAmountError.value = ''
        return true
      }
    }

    const validateLeverage = () => {
      if (leverageValue.value <= 1 || leverageValue.value > 100 || !Number.isInteger(leverageValue.value)) {
        leverageError.value = 'Leverage must be an integer between 2 and 100'
        leverageValue.value = 2
        return false
      } else {
        leverageError.value = ''
        return true
      }
    }

    const getSharePrice = async () => {
      try {
        console.log("selectedOption.value", selectedOption.value)
        console.log("selectedStrike.value.slot", selectedStrike.value.slot)
        // clean dirty
        leverageEnabled.value = false
        leverageValue.value = 2
        buyAmount.value = 1
        marginSpend.value = 0
        lqdtTime.value = 'n/a'
        let otype = selectedOption.value === "CALL" ? 0 : 1
        let sharePriceRaw = await fetchSharePrice(modal, otype, selectedStrike.value.slot)
        sharePrice.value = parseFloat(sharePriceRaw.toNumber() / usdtDecimal.value).toFixed(2)
        totalCost.value = sharePrice.value
        console.log("sharePrice.value", sharePrice.value)
      } catch (error) {
        console.log('getSharePrice error: ', error)
      }
    }

    const getMarginAndLqdt = () => {
      try {
        console.log("getMarginAndLqdt triggered - leverageEnabled.value: ", leverageEnabled.value)
        if (!validateLeverage || !sharePrice.value || !selectedOption.value) { return }
        if (leverageEnabled.value) {
          let levg = leverageValue.value
          console.log('leverageValue.value', leverageValue.value)
          totalCost.value = parseFloat(buyAmount.value * sharePrice.value / levg).toFixed(2)
          marginSpend.value = parseFloat(buyAmount.value * sharePrice.value - (buyAmount.value * sharePrice.value) / levg).toFixed(2)
          if (marginSpend.value > 10 ** 6) { marginSpend.value = marginSpend.value.toExponential(2) }
          console.log('current slc price: ', selectedStrike.value.value)
          console.log('current eth price: ', currentETHPrice.value)
          if ((selectedOption.value === "CALL" && selectedStrike.value.value > currentETHPrice.value) || (selectedOption.value === "PUT" && selectedStrike.value.value < currentETHPrice.value)) {
            const now = Math.floor(Date.now() / 1000)
            const exp = 24 * 3600
            let calcLqdtTime = now + (exp * totalCost.value / (buyAmount.value * sharePrice.value))
            lqdtTime.value = formatCountdown(Math.floor(calcLqdtTime))
          }
        } else {
          marginSpend.value = 0
          lqdtTime.value = 'n/a'
          totalCost.value = parseFloat(buyAmount.value * sharePrice.value).toFixed(2)
        }
      } catch (error) {
        console.log('getMarginAndLqdt error: ', error)
      }
    }

    const getTotalCost = () => {
      try {
        if (!validateBuyAmount() || !selectedStrike.value || !selectedOption.value || !sharePrice.value) { return 0 }
        if (!leverageEnabled.value) {
          totalCost.value = parseFloat(sharePrice.value * buyAmount.value).toFixed(2)
          marginSpend.value = 0
        } else {
          let levg = leverageValue.value
          totalCost.value = parseFloat(buyAmount.value * sharePrice.value / levg).toFixed(2)
          marginSpend.value = parseFloat(buyAmount.value * sharePrice.value - (buyAmount.value * sharePrice.value) / levg).toFixed(2)
          if (marginSpend.value > 10 ** 6) { marginSpend.value = marginSpend.value.toExponential(2) }
        }

      } catch (error) {
        console.log('getTotalCost error: ', error)
      }
    }

    const showMenuA = async () => {
      try {
        console.log("showMenuA")
        showMenu.value = true
        console.log('leverageEnabled.value', leverageEnabled.value)
        console.log('leverageValue.value', leverageValue.value)
        let leverage = leverageEnabled.value ? leverageValue.value : 1
        console.log('leverage', leverage)
        console.log('selectedStrike.value.slot', selectedStrike.value.slot)
        console.log('buyAmount.value', buyAmount.value)
        isLoading.value = true
        let fetchResp = await fetchPreviewOpenPosition(modal, selectedOption.value === 'CALL' ? 0 : 1, selectedStrike.value.slot, buyAmount.value, leverage)
        isLoading.value = false
        // sharePrice_display.value = parseFloat(fetchResp[0].toNumber() / usdtDecimal.value).toFixed(6)
        // usdtPayment_display.value = parseFloat(fetchResp[1].toNumber() / usdtDecimal.value).toFixed(6)
        sharePrice_display.value = (toBig(fetchResp[0]) / toBig(usdtDecimal.value)).toString()
        usdtPayment_display.value = (toBig(fetchResp[1]) / toBig(usdtDecimal.value)).toString()
        console.log('toBig(fetchResp[2]): ', toBig(fetchResp[2]))
        lqdtTimePreview.value = toBig(fetchResp[2]).isZero() ? null : formatCountdown(fetchResp[2].toNumber())
        usdtPayment.value = fetchResp[1]
      } catch (error) {
        console.log('showMenuA error: ', error)
      }
    }

    const closeMenuA = () => {
      showMenu.value = false
      sharePrice_display.value = null
      usdtPayment_display.value = null
      usdtPayment.value = null
    }

    const confirmSelection = async () => {
      try {
        validateBuyAmount()
        if (!buyAmountError.value && selectedStrike.value && selectedOption.value) {
          console.log(`Open ${selectedOption.value}, amount: ${buyAmount.value}, strike: ${selectedStrike.value.value}, slot: ${selectedStrike.value.slot}`)
          let _share = buyAmount.value
          let _slot = selectedStrike.value.slot
          let _otype = selectedOption.value === "CALL" ? 0 : 1
          let _inputUsdt = usdtPayment.value
          let _allowance = await allowance(modal, true)
          if (_allowance.lt(_inputUsdt)) {
            try {
              startTransaction(root.value, 'Approve')
              const [approveTx, err] = await approve(modal, _inputUsdt.mul(ethers.BigNumber.from("12")).div(ethers.BigNumber.from("10")), true)
              if (approveTx) {
                await approveTx.wait()
                completeTransaction(root.value, approveTx.hash)
              }
              else {
                failTransaction(root.value, err)
                return
              }
            } catch (error) {
              console.log('Transaction failed:', error)
              failTransaction(root.value, error.message)
            }
          }
          try {
            console.log('root: ', root)
            startTransaction(root.value, 'Open Position')
            let txResp
            if (!leverageEnabled.value) {
              console.log('CONFIRM - leverageEnabled.value', leverageEnabled.value)
              txResp = await openPositionSpot(modal, _otype, _slot, _share)
            } else {
              console.log('CONFIRM - leverageEnabled.value', leverageEnabled.value)
              txResp = await openPositionLevg(modal, _otype, _slot, _share, leverageValue.value)
            }
            console.log('txResp', txResp)
            let tx = txResp[0]
            let err = txResp[1]
            if (tx) {
              await tx.wait()
              if (!leverageEnabled.value) {
                await store.dispatch('fetchRoundPositionSpot', { p_modal: modal, p_curRound: currentRound.value, p_preRound: currentRound.value - 1 })
              } else {
                await store.dispatch('fetchRoundPositionLevg', { p_modal: modal, p_curRound: currentRound.value, p_preRound: currentRound.value - 1 })
                await store.dispatch('fetchLqdtPosition', { p_modal: modal, p_fromBlock: lastStrikeBlock.value })
              }
              console.log('Open Position Successfully! TX Hash: ' + tx.hash)
              completeTransaction(root.value, tx.hash)
            } else {
              console.log('null tx error: ', err)
              failTransaction(root.value, err)
            }
          } catch (error) {
            console.log('Transaction failed:', error)
            failTransaction(root.value, error.message)
          } finally {
            await store.dispatch('fetchHomePageInfo', { p_modal: modal })
            closeMenuA()
            selectedStrike.value = null
            selectedOption.value = null
            leverageEnabled.value = false
            leverageValue.value = 2
            buyAmount.value = 1
          }
        }
      } catch (error) {
        alert('confirmSelection error: ', error)
      }
    }

    const toBig = (input) => {
      return ethers.BigNumber.from(input)
    }

    onMounted(() => {
      try {
        root.value = getCurrentInstance().proxy.$root
        ethDecimal = computed(() => store.getters.ETH_DECIMAL)
        usdtDecimal = computed(() => store.getters.USDT_DECIMAL)
        setInterval(updateCountdown, 1000)
        if (strikeInfos.value && strikeInfos.value.length > 0) {
          destroyChart()
          createChart()
        }
      } catch (error) {
        console.log('onMounted error: ', error)
      }

    })

    return {
      store,
      modal,
      homePageParams,
      strikeInfos,
      currentRound,
      lastStrike,
      nextStrike,
      lastStrikeBlock,
      lastStrikeETHPrice,
      currentETHPrice,
      totalMargin,
      buyAmount,
      countdown,
      buyAmountError,
      strikes,
      selectedStrike,
      selectedOption,
      leverageEnabled,
      leverageValue,
      leverageError,
      showMenu,
      sharePrice_display,
      usdtPayment_display,
      usdtPayment,
      lqdtTimePreview,
      isLoading,
      isRoundClosed,
      sharePrice,
      totalCost,
      marginSpend,
      lqdtTime,
      isLevgEnabled,
      myChart,
      selectOption,
      confirmSelection,
      validateBuyAmount,
      validateLeverage,
      showMenuA,
      closeMenuA,
      getSharePrice,
      getMarginAndLqdt,
      getTotalCost,
    }
  },
}
</script>
