import { ethers } from 'ethers'

const SIGNATURE_VALIDITY = 30 * 60 * 1000 // 30 minutes

export const checkVerifiedAddress = async (address) => {
    try {
        if (!address) {
            return false
        }
        const storedData = localStorage.getItem('verifiedAddresses')
        if (!storedData) return false

        const verifiedAddresses = JSON.parse(storedData)
        const addressData = verifiedAddresses[address]
        if (!addressData) return false

        const now = Date.now()
        if (now - addressData.timestamp < SIGNATURE_VALIDITY) {
            return true
        } else {
            delete verifiedAddresses[address]
            localStorage.setItem('verifiedAddresses', JSON.stringify(verifiedAddresses))
            return false
        }
    } catch (error) {
        console.log('Error checkVerifiedAddress:', error)
        return false
    }
}

export const verifySignMessage = async (walletProvider) => {
    console.log('verifySignMessage triggered')
    try {
        const ethersProvider = new ethers.providers.Web3Provider(walletProvider)
        const signer = ethersProvider.getSigner()
        const address = await signer.getAddress()
        const message = `Sign this message to confirm your address: ${address}`
        const signature = await signer.signMessage(message)

        // Verify the signature
        const recoveredAddress = ethers.utils.verifyMessage(message, signature)
        if (recoveredAddress === address) {
            console.log('Signature is valid!')
            const verifiedAddresses = JSON.parse(localStorage.getItem('verifiedAddresses') || '{}')
            verifiedAddresses[address] = { signature, timestamp: Date.now() }
            localStorage.setItem('verifiedAddresses', JSON.stringify(verifiedAddresses))
            return true
        } else {
            console.log('Signature verification failed.')
            return false
        }
    } catch (error) {
        console.log('Error during wallet connect:', error)
        return false
    }
}
