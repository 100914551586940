const assembleDataSpot = (rawPosition, ethDecimal, usdtDecimal) => {
  if (rawPosition && rawPosition.length > 0) {
    const formattedPositions = rawPosition
      .filter(item => Number(item.Shares) > 0)
      .map(item => ({
        type: item.Otype == 0 ? 'CALL' : 'PUT',
        strike: Number(item.StrikePrice) / ethDecimal,
        slot: Number(item.Slot),
        shares: Number(item.Shares),
        values: parseFloat(Number(item.Values) / usdtDecimal).toFixed(2),
        redeems: parseFloat(Number(item.Redeems) / usdtDecimal).toFixed(2)
      }))
    console.log('FormattedPositions SPOT', formattedPositions)
    return formattedPositions
  }
}

const assembleDataLevg = (rawPosition, ethDecimal, usdtDecimal) => {
  if (rawPosition && rawPosition.length > 0) {
    const formattedPositions = rawPosition
      .filter(item => Number(item.Shares) > 0)
      .map(item => ({
        type: item.Otype == 0 ? 'CALL' : 'PUT',
        strike: Number(item.StrikePrice) / ethDecimal,
        slot: Number(item.Slot),
        shares: Number(item.Shares),
        margin: parseFloat(Number(item.Margin) / usdtDecimal).toFixed(2),
        values: parseFloat(Number(item.Values) / usdtDecimal).toFixed(2),
        redeems: parseFloat(Number(item.Redeems) / usdtDecimal).toFixed(2),
        leverage: Number(item.Leverage),
        liquidationTime: Number(item.LiquidationTime)
      }))
    console.log('FormattedPositions LEVG', formattedPositions)
    return formattedPositions
  }
}

export {
  assembleDataSpot,
  assembleDataLevg
}