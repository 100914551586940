<template>
  <div v-if="showBanner" class="transaction-banner-overlay">
    <div class="transaction-banner">

      <button v-if="!isProcessing" class="close-button" @click="closeBanner">X</button>
      <h3>Proceeding Transaction: {{ transactionName }}</h3>
      <div v-if="isProcessing" class="spinner-container">
        <div class="spinner"></div>
      </div>
      <div v-if="transactionHash" class="result">
        <p>Transaction Completed!</p>
        <p class="hash">
          <a :href="transactionUrl" target="_blank">{{ transactionHash }}</a>
        </p>
      </div>
      <div v-if="transactionError" class="result">
        <p class="error">Transaction Failed!</p>
        <p class="error">{{ transactionError }}</p>
      </div>
    </div>

  </div>
</template>


<script>
import { ref, computed } from 'vue'
import '../assets/styles/global-style.css'

export default {
  name: 'TransactionBanner',
  setup() {
    const showBanner = ref(false)
    const transactionName = ref('')
    const transactionHash = ref('')
    const transactionError = ref('')
    const isProcessing = ref(true)

    const transactionUrl = computed(() => {
      // return `https://sepolia.etherscan.io/tx/${transactionHash.value}` /// Sepolia
      return `https://sepolia.arbiscan.io/tx/${transactionHash.value}` /// Arbitrum Sepolia
    })

    const startTransaction = (name) => {
      transactionName.value = name
      transactionHash.value = ''
      transactionError.value = ''
      isProcessing.value = true
      showBanner.value = true
    }

    const completeTransaction = (hash) => {
      transactionHash.value = hash
      isProcessing.value = false
    }

    const failTransaction = (error) => {
      transactionError.value = error
      isProcessing.value = false
    }

    const closeBanner = () => {
      showBanner.value = false
    }

    return {
      showBanner,
      transactionName,
      transactionHash,
      transactionError,
      isProcessing,
      transactionUrl,
      startTransaction,
      completeTransaction,
      failTransaction,
      closeBanner,
    }
  }
}
</script>