// src/store/index.js
import { createStore } from 'vuex'
import { ethers } from 'ethers'
import { fetchHomePageInfo, fetchRoundPosition, fetchLiquidityInfo, eventFilter } from '../assets/scripts/web3utils'
import { assembleDataSpot, assembleDataLevg } from '../assets/scripts/datautils'

const _GLOBAL_CHAIN_ID_ = 421614
const _ETH_DECIMAL_ = 10 ** 8
const _USDT_DECIMAL_ = 10 ** 6

const store = createStore({
    state: {
        GLOBAL_CHAIN_ID: null,
        ETH_DECIMAL: null,
        USDT_DECIMAL: null,
        homePageParams: null,
        strikeInfos: [],
        strikeInfoMap: new Map(),
        curSpotPosition: [],
        preSpotPosition: [],
        curLevgPosition: [],
        preLevgPosition: [],
        closeEthPriceSpot: ethers.BigNumber.from(0),
        closeEthPriceLevg: ethers.BigNumber.from(0),
        isDataLoadedSpot: false,
        isDataLoadedLevg: false,
        lqdtPosition: [],
        isLqdtPosLoaded: false,
        liquidityInfo: [],
        isLiquidityInfoLoaded: false,
    },
    mutations: {
        setHomePageParams(state, params) {
            state.GLOBAL_CHAIN_ID = _GLOBAL_CHAIN_ID_
            state.ETH_DECIMAL = _ETH_DECIMAL_
            state.USDT_DECIMAL = _USDT_DECIMAL_
            state.homePageParams = params
        },
        setStrikeInfos(state, infos) {
            state.strikeInfos = infos
            state.strikeInfoMap.clear(); // Clear the Map before repopulating
            infos.forEach(info => {
                state.strikeInfoMap.set(info.Slot, info.StrikePrice);
            });
        },
        setCurSpotPosition(state, curPos) {
            state.curSpotPosition = curPos
        },
        setPreSpotPosition(state, prePos) {
            state.preSpotPosition = prePos
        },
        setCurLevgPosition(state, curPos) {
            state.curLevgPosition = curPos
        },
        setPreLevgPosition(state, prePos) {
            state.preLevgPosition = prePos
        },
        setCloseEthPriceSpot(state, price) {
            state.closeEthPriceSpot = price
        },
        setCloseEthPriceLevg(state, price) {
            state.closeEthPriceLevg = price
        },
        setDataLoadedSpot(state, isLoaded) {
            state.isDataLoadedSpot = isLoaded
        },
        setDataLoadedLevg(state, isLoaded) {
            state.isDataLoadedLevg = isLoaded
        },
        setLqdtPosData(state, lqdtPos) {
            state.lqdtPosition = lqdtPos
        },
        setDataLoadedLqdtPos(state, isLoaded) {
            state.isLqdtPosLoaded = isLoaded
        },
        setLiquidityInfo(state, liquidityInfo) {
            state.liquidityInfo = liquidityInfo
        },
        setDataLoadedLiquidityInfo(state, isLoaded) {
            state.isLiquidityInfoLoaded = isLoaded
        }
    },
    actions: {
        async fetchHomePageInfo({ commit }, {p_modal}) {
            try {
                const [hpp, sInfos] = await fetchHomePageInfo(p_modal)
                commit('setHomePageParams', hpp)
                commit('setStrikeInfos', sInfos)
            } catch (error) {
                console.log("Failed to fetch home page info:", error)
            }
        },
        async fetchRoundPositionSpot({ commit }, { p_modal, p_curRound, p_preRound }) {
            try {
                if(!p_modal.getIsConnected()) { return }
                const [, curPos] = await fetchRoundPosition(p_modal, p_curRound, true)
                commit('setCurSpotPosition', assembleDataSpot(curPos, _ETH_DECIMAL_, _USDT_DECIMAL_))
                const [closeEthPriceSpot, prePos] = p_preRound >= 0 ? await fetchRoundPosition(p_modal, p_preRound, true) : [ethers.BigNumber.from(0), []]
                commit('setCloseEthPriceSpot', closeEthPriceSpot)
                commit('setPreSpotPosition', assembleDataSpot(prePos, _ETH_DECIMAL_, _USDT_DECIMAL_))
                commit('setDataLoadedSpot', true)
            } catch (error) {
                console.log("Failed to fetch round position Spot:", error)
            }
        },
        async fetchRoundPositionLevg({ commit }, { p_modal, p_curRound, p_preRound }) {
            try {
                if(!p_modal.getIsConnected()) { return }
                const [, curPos] = await fetchRoundPosition(p_modal, p_curRound, false)
                commit('setCurLevgPosition', assembleDataLevg(curPos, _ETH_DECIMAL_, _USDT_DECIMAL_))
                const [closeEthPriceLevg, prePos] = p_preRound >= 0 ? await fetchRoundPosition(p_modal, p_preRound, false) : [ethers.BigNumber.from(0), []]
                commit('setCloseEthPriceLevg', closeEthPriceLevg)
                commit('setPreLevgPosition', assembleDataLevg(prePos, _ETH_DECIMAL_, _USDT_DECIMAL_))
                commit('setDataLoadedLevg', true)
            } catch (error) {
                console.log("Failed to fetch round position Levg:", error)
            }
        },
        async fetchRoundPositionSingle({ commit }, { p_modal, p_preRound, p_isSpot }) {
            try {
                if (!p_modal.getIsConnected()) { return }
                const [closeEthPrice, prePos] = await fetchRoundPosition(p_modal, p_preRound, p_isSpot)
                if (p_isSpot) {
                    commit('setPreSpotPosition', assembleDataSpot(prePos, _ETH_DECIMAL_, _USDT_DECIMAL_))
                    commit('setCloseEthPriceSpot', closeEthPrice)
                } else {
                    commit('setPreLevgPosition', assembleDataLevg(prePos, _ETH_DECIMAL_, _USDT_DECIMAL_))
                    commit('setCloseEthPriceLevg', closeEthPrice)
                }
            } catch (error) {
                console.log("Failed to fetch round position single:", error)
            }
        },
        async fetchLqdtPosition({ commit }, {p_modal, p_fromBlock}) {
            try {
                if(!p_modal.getIsConnected()) { return }
                let eventLogs = await eventFilter(p_modal, p_fromBlock)
                commit('setLqdtPosData', eventLogs)
                commit('setDataLoadedLqdtPos', true)
            } catch (error) {
                console.log("Failed to fetch liquidation position:", error)
            }
        },
        async fetchLiquidityInfo({ commit }, {p_modal}) {
            try {
                if(!p_modal.getIsConnected()) { return }
                let liquidityInfo = await fetchLiquidityInfo(p_modal)
                commit('setLiquidityInfo', liquidityInfo)
                commit('setDataLoadedLiquidityInfo', true)
            } catch (error) {
                console.log("Failed to fetch liquidity info:", error)
            }
        },
        async unfetchRoundPositionSpot({ commit }) {
            try {
                commit('setCurSpotPosition', null)
                commit('setPreSpotPosition', null)
                commit('setDataLoadedSpot', false)
            } catch (error) {
                console.log("Failed to UNfetch round position Spot:", error)
            }
        },
        async unfetchRoundPositionLevg({ commit }) {
            try {
                commit('setCurLevgPosition', null)
                commit('setPreLevgPosition', null)
                commit('setDataLoadedLevg', false)
            } catch (error) {
                console.log("Failed to UNfetch round position Levg:", error)
            }
        },
        async unfetchLiquidityInfo({ commit }) {
            try {
                commit('setLiquidityInfo', null)
                commit('setDataLoadedLiquidityInfo', false)
            } catch (error) {
                console.log("Failed to UNfetch liquidity info:", error)
            }
        }
    },
    getters: {
        GLOBAL_CHAIN_ID: (state) => state.GLOBAL_CHAIN_ID,
        ETH_DECIMAL: (state) => state.ETH_DECIMAL,
        USDT_DECIMAL: (state) => state.USDT_DECIMAL,
        homePageParams: (state) => state.homePageParams,
        strikeInfos: (state) => state.strikeInfos,
        strikeInfoMap: (state) => state.strikeInfoMap, 
        curSpotPosition: (state) => state.curSpotPosition,
        preSpotPosition: (state) => state.preSpotPosition,
        curLevgPosition: (state) => state.curLevgPosition,
        preLevgPosition: (state) => state.preLevgPosition,
        closeEthPriceSpot: (state) => state.closeEthPriceSpot,
        closeEthPriceLevg: (state) => state.closeEthPriceLevg,
        isDataLoadedSpot: (state) => state.isDataLoadedSpot,
        isDataLoadedLevg: (state) => state.isDataLoadedLevg,
        lqdtPosition: (state) => state.lqdtPosition,
        isLqdtPosLoaded: (state) => state.isLqdtPosLoaded,
        liquidityInfo: (state) => state.liquidityInfo,
        isLiquidityInfoLoaded: (state) => state.isLiquidityInfoLoaded
    }
})

export default store
